import Button from '../Input/Button'
import { ArrowElbowDownRight } from '@phosphor-icons/react'
import { TransportCalculator } from '../Transport/Transport'
import UnitService from '../../services/unit'
import { Input } from '../../services/input'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useInputType } from '../../hooks/useInputType'
import { VariableServicesContext } from '../../services'
import { ModelSelectCell } from '../CategoryModel/ModelSelectCell'
import TransportService from '../../services/transport'
import { AmountInput } from '../Input/Amount'
import { useTransportType } from '../../hooks/useTransportType'
import { useTransportInstance } from '../../hooks/useTransportInstance'

export const InputTransport = (props: {
    input?: Input
    dragHandle: ReactNode
    disabled?: boolean
    onChange: (input: Input) => void
}) => {
    const { inputService } = useContext(VariableServicesContext)
    const [input, setInput] = useState<Input | undefined>(props.input)
    const { editTransportInstance } = useInputType({ instanceId: input?.uuid || '' })

    const transportInstance = useTransportInstance({ transportInstance: input?.transportInstance })
    const transportType = useTransportType({ transportType: transportInstance?.transportType })

    useEffect(() => setInput(props.input), [props.input])

    if (!input || !input.transportInstance) return null

    return (
        <>
            <td className='align-middle'>{props.dragHandle}</td>
            <td className='align-middle small text-nowrap' style={{ paddingLeft: '.75rem' }}>
                <Button
                    className={[
                        'd-flex align-items-center gap-2',
                        'btn btn-sm p-0 bg-light-hover rounded-1',
                        'fs-inherit text-inherit text-start',
                    ].join(' ')}
                    style={{ maxWidth: '10rem' }}
                    disabled={props.disabled}
                    onClick={() => editTransportInstance(input!, 'name')}
                >
                    {input.transportFor && <ArrowElbowDownRight className='nt--4 flex-shrink-0' />}
                    <span className='text-overflow-ellipsis'>
                        {TransportService.getTransportTypeName(transportType)}
                    </span>
                </Button>
            </td>
            <td className='align-middle position-relative' style={{ maxWidth: '14rem' }}>
                <ModelSelectCell input={input} field='transport' />
                <TransportCalculator
                    transportInstance={input?.transportInstance}
                    transportFor={input.transportFor}
                    node={input}
                    startOpen={true}
                    disabled={props.disabled}
                    onChange={async (transportInstance) => {
                        await inputService
                            .updateInput({
                                uuid: input.uuid,
                                quantity: transportInstance.tkm || 0,
                                transportInstance: transportInstance,
                                unit: UnitService.unitByCode['tkm'],
                            })
                            .then((ui) => {
                                const _updatedInput: Input = { ...input, ...ui, transportInstance: transportInstance }
                                setInput(() => _updatedInput)
                                props.onChange(_updatedInput)
                            })
                    }}
                />
            </td>
            <td />
            <td style={{ width: '5rem' }} className='align-middle position-relative'>
                <AmountInput
                    amount={{ quantity: input.transportInstance.tkm || input.quantity, unit: input.unit }}
                    disabled={true}
                    inputFieldProps={{ isNumber: true, required: true, extraClassName: 'bg-transparent' }}
                    prettyNumberProps={{ showZeroesToNumber: true }}
                    onChange={() => {}}
                />
            </td>
            <td />
        </>
    )
}
