import { Product, ProductVisibility } from '../../services/product'
import { useContext, useMemo } from 'react'
import { VariableServicesContext } from '../../services'
import { Archive, Cube, IconProps, Package } from '@phosphor-icons/react'
import { Input } from '../../services/input'

export const ProductIcon = (
    props: IconProps & {
        product?: Product
        isProduct?: boolean
        isArchived?: boolean
        isBundled?: boolean
        input?: Input
    },
) => {
    const { companyService } = useContext(VariableServicesContext)

    const iconProps = useMemo(() => {
        const { isProduct, isArchived, isBundled, product, ...rest } = props
        return rest
    }, [props])

    if (props.isArchived || props.product?.visibility === ProductVisibility.ARCHIVED) {
        return <Archive {...iconProps} />
    }

    if (props.isProduct || companyService.isMyCompany(props.product?.productOf)) {
        return <Package {...iconProps} />
    }

    if (props.isBundled || props.input?.lifecycleStages) {
        return <Cube {...iconProps} />
    }

    return <Cube {...iconProps} />
}
