import { Table, TableColumn, TableProps } from './Table'
import ImpactService, {
    Impact,
    ImpactCategory,
    ImpactIndicator,
    ImpactMethod,
    ImpactType,
    ImpactUnit,
} from '../services/impact'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Button, { ButtonProps } from './Input/Button'
import { VariableServicesContext } from '../services'
import { PrettyNumber } from './PrettyNumber'
import FlagService, { FlagType } from '../services/flag'
import { useSearchParams } from 'react-router-dom'
import { StandardAttributes, VariableNode } from '../types'
import { GridNine } from '@phosphor-icons/react'
import Utils from '../services/utils'
import { ApplicationContext } from '../context'
import Copy from './Copy'
import { SearchBox } from './Input/SearchBox'

export interface ImpactTableProps extends StandardAttributes {
    node?: VariableNode
    impacts?: Impact[]
    methods?: ImpactMethod[]
    categories?: ImpactCategory[]
    indicators?: ImpactIndicator[]
    types?: ImpactType[]
    units?: ImpactUnit[]
    buttonAttrs?: Partial<ButtonProps>
    tableAttrs?: Partial<TableProps<Impact, any>>
    openInModal?: boolean
    fetch?: boolean
}

export const ImpactTable = (props: ImpactTableProps) => {
    const context = useContext(ApplicationContext)
    const { uiService, impactService } = useContext(VariableServicesContext)
    const [qs] = useSearchParams()
    const [filteredTotal, setFilteredTotal] = useState<number>(0)
    const [impacts, setImpacts] = useState<Impact[]>(props.impacts || [])
    const [fetching, setFetching] = useState<boolean>(false)
    const [hasFetched, setHasFetched] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>('')

    const fetchImpacts = useCallback(() => {
        setFetching(true)
        impactService
            .getImpact(props.node?.uuid)
            .then(setImpacts)
            .then(() => setHasFetched(true))
            .catch(console.warn)
            .finally(() => setFetching(false))
    }, [props.node])

    useEffect(() => {
        if (props.fetch) fetchImpacts()
    }, [props.fetch])

    useEffect(() => {
        if (hasFetched) fetchImpacts()
    }, [props.node])

    const columns: TableColumn<Impact, any>[] = useMemo(() => {
        const cols: TableColumn<Impact, any>[] = [
            {
                label: (
                    <>
                        Method <PrettyNumber num={filteredTotal} surround='()' />
                    </>
                ),
                sortKey: 'method',
                cellAttrs: { style: { width: '10rem' } },
                content: (impact: Impact) => (
                    <span className='d-flex flex-column text-overflow-ellipsis w-inherit' title={impact.method}>
                        {impact.method}
                        {Utils.inDebugMode() && <Copy value={impact.uuid} extraClassName='very-small' />}
                    </span>
                ),
            },
            {
                label: 'Category',
                sortKey: 'category',
                cellAttrs: { style: { width: '14rem' } },
                content: (impact: Impact) => (
                    <span className='d-block text-overflow-ellipsis w-inherit' title={impact.category}>
                        {impact.category}
                    </span>
                ),
            },
            {
                label: 'Indicator',
                sortKey: 'indicator',
                cellAttrs: { style: { width: '4rem' } },
                content: (impact: Impact) => impact.indicator,
            },
            {
                label: 'Unit',
                cellAttrs: { style: { width: '4rem' } },
                content: (impact: Impact) => impact.unit,
            },
        ]
        if (Utils.inDebugMode()) {
            cols.push({
                label: 'Type',
                cellAttrs: { style: { width: '4rem' } },
                content: (impact: Impact) => impact.type,
            })
        }
        ImpactService.impactValues.forEach((stage) => {
            let label = stage
            switch (stage) {
                case 'A1_A3':
                    label = 'A1-A3'
                    break
                case 'upstream':
                    label = 'Upstream'
                    break
                case 'direct':
                    label = 'Direct'
                    break
                case 'downstream':
                    label = 'Downstream'
                    break
                case 'totalCarbonFootprint':
                    label = 'Total'
            }
            cols.push({
                label: <span className='text-nowrap'>{label}</span>,
                sortKey: stage,
                headerAttrs: { extraClassName: 'text-end' },
                cellAttrs: { extraClassName: 'text-end', style: { width: '4rem' } },
                content: (impact: Impact) => (
                    <PrettyNumber
                        // @ts-ignore
                        num={impact[stage]}
                        precision={2}
                        showZeroesToPrecision={true}
                        onUndefined='simple-dash'
                        // suffix={<span className='d-block text-muted small'>{impact.unit}</span>}
                    />
                ),
            })
        })
        return cols
    }, [filteredTotal, context.stores.ui?.debugUpdated])

    const orderBy = useMemo(() => qs.get('orderBy') || 'name', [qs])

    const sortFn = useCallback(
        (a: Impact, b: Impact) => {
            if (orderBy === 'method') return a.method.localeCompare(b.method)
            if (orderBy === 'category') return a.category.localeCompare(b.category)
            if (orderBy === 'indicator') return a.indicator.localeCompare(b.indicator)
            if (orderBy === 'footprint') return parseFloat(a.A1 || '0') - parseFloat(b.A1 || '0')
            if (a.method < b.method) return -1
            if (a.method > b.method) return 1
            if (a.category < b.category) return -1
            if (a.category > b.category) return 1
            if (a.indicator < b.indicator) return -1
            if (a.indicator > b.indicator) return 1
            return 0
        },
        [orderBy],
    )

    const impactMethods = useMemo(() => props.methods || ImpactService.defaultMethods, [props.methods])
    const impactCategories = useMemo(() => props.categories || [], [props.categories])
    const impactIndicators = useMemo(() => props.indicators || [], [props.indicators])
    const impactTypes = useMemo(() => props.types || [], [props.types])
    const impactUnits = useMemo(() => props.units || [], [props.units])

    const hasStatic = useMemo(() => impacts?.some((i) => i.type === ImpactType.Static), [impacts])

    const filterFn = useCallback(
        (i: Impact) => {
            let pass = true
            if (hasStatic && i.type !== ImpactType.Static) return false
            if (searchTerm && pass) {
                return (
                    i.method.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    i.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    i.indicator.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    i.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    i.unit.toLowerCase().includes(searchTerm.toLowerCase())
                )
            }
            if (Utils.inDebugMode()) return true
            if (impactMethods.length) pass = impactMethods.includes(i.method)
            if (impactCategories.length) pass = impactCategories.includes(i.category)
            if (impactIndicators.length) pass = impactIndicators.includes(i.indicator)
            if (impactTypes.length) pass = impactTypes.includes(i.type)
            if (impactUnits.length) pass = impactUnits.includes(i.unit)
            return pass
        },
        [impactMethods, impactCategories, impactIndicators, impactTypes, impactUnits, hasStatic, searchTerm],
    )

    if (!FlagService.enabledFlags.has(FlagType.EnableAllImpactIndicators) && !Utils.inDebugMode()) return null

    if (!impacts.length) {
        if (fetching) return <div className='spinner-border spinner-border-sm' />
        if (hasFetched) return 'No data'
        return (
            <Button
                element='div'
                className={[
                    props.buttonAttrs?.className || 'small underline-on-hover',
                    props.buttonAttrs?.extraClassName,
                ].join(' ')}
                style={props.buttonAttrs?.style}
                onClick={() => {
                    if (props.openInModal) uiService.setImpactNode(props.node)
                    else fetchImpacts()
                }}
            >
                {props.buttonAttrs?.children || (
                    <>
                        <GridNine /> Show all impacts
                    </>
                )}
            </Button>
        )
    }

    return (
        <>
            <SearchBox
                extraClassName='d-block position-relative'
                inputFieldProps={{ defaultValue: searchTerm, onChange: (st) => setSearchTerm(st) }}
            />
            <Table<Impact, any>
                columns={columns}
                data={impacts}
                filterFn={filterFn}
                sortFn={sortFn}
                onData={(d) => setFilteredTotal(d.length)}
                containerAttrs={{ className: 'flex-grow-1' }}
                tableAttrs={{
                    extraClassName: [props.className || 'table-sm small w-100', props.extraClassName].join(' '),
                }}
                rowAttrs={{ extraClassName: 'bg-light-hover hover-parent' }}
                {...props.tableAttrs}
            />
        </>
    )
}
