import InputField from '../Input/InputField'
import Utils from '../../services/utils'
import { Product } from '../../services/product'
import { StandardAttributes } from '../../types'
import { ReactNode, useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { useProduct } from '../../hooks/useProduct'
import { LCACode, UseStageCategoryType } from '../../services/useStage'
import InputService from '../../services/input'

export const StaticValues = (
    props: StandardAttributes & {
        product?: Product
        upstreamLabel?: ReactNode
        downstreamLabel?: ReactNode
        fields?: (UseStageCategoryType | LCACode)[]
        onChange: (properties: Partial<Product>) => void
    },
) => {
    const context = useContext(ApplicationContext)
    const { companyService } = useContext(VariableServicesContext)
    const product = useProduct({ product: props.product })
    const isSynced = useMemo(
        () => product?.syncId !== undefined && !companyService.isMyCompany(product?.productOf),
        [product?.syncId, context.stores.company?.uuid],
    )

    const labels = useMemo(() => {
        const labelMap: Map<string, string | undefined> = new Map()
        // labelMap.set(UseStageCategoryType.UPSTREAM, 'Upstream')
        // labelMap.set(UseStageCategoryType.DIRECT, 'Direct')
        // labelMap.set(UseStageCategoryType.DOWNSTREAM, 'Downstream')
        // labelMap.set(UseStageCategoryType.A1_A3, 'A1-A3')
        labelMap.set(LCACode.A1, InputService.getUseStageByCode(LCACode.A1)?.name)
        labelMap.set(LCACode.A2, InputService.getUseStageByCode(LCACode.A2)?.name)
        labelMap.set(LCACode.A3, InputService.getUseStageByCode(LCACode.A3)?.name)
        labelMap.set(LCACode.A4, InputService.getUseStageByCode(LCACode.A4)?.name)
        labelMap.set(LCACode.A5, InputService.getUseStageByCode(LCACode.A5)?.name)
        labelMap.set(LCACode.B1, InputService.getUseStageByCode(LCACode.B1)?.name)
        labelMap.set(LCACode.B2, InputService.getUseStageByCode(LCACode.B2)?.name)
        labelMap.set(LCACode.B3, InputService.getUseStageByCode(LCACode.B3)?.name)
        labelMap.set(LCACode.B4, InputService.getUseStageByCode(LCACode.B4)?.name)
        labelMap.set(LCACode.B5, InputService.getUseStageByCode(LCACode.B5)?.name)
        labelMap.set(LCACode.B6, InputService.getUseStageByCode(LCACode.B6)?.name)
        labelMap.set(LCACode.B7, InputService.getUseStageByCode(LCACode.B7)?.name)
        labelMap.set(LCACode.C1, InputService.getUseStageByCode(LCACode.C1)?.name)
        labelMap.set(LCACode.C2, InputService.getUseStageByCode(LCACode.C2)?.name)
        labelMap.set(LCACode.C3, InputService.getUseStageByCode(LCACode.C3)?.name)
        labelMap.set(LCACode.C4, InputService.getUseStageByCode(LCACode.C4)?.name)
        labelMap.set(LCACode.D, InputService.getUseStageByCode(LCACode.D)?.name)
        return labelMap
    }, [])

    const upstreamFields = useMemo(() => {
        return [LCACode.A1, LCACode.A2]
    }, [])

    const directFields = useMemo(() => {
        return [LCACode.A3]
    }, [])

    const downstreamFields = useMemo(() => {
        return [
            LCACode.A4,
            LCACode.A5,
            LCACode.B1,
            LCACode.B2,
            LCACode.B3,
            LCACode.B4,
            LCACode.B5,
            LCACode.B6,
            LCACode.B7,
            LCACode.C1,
            LCACode.C2,
            LCACode.C3,
            LCACode.C4,
            LCACode.D,
        ]
    }, [])

    const fields = useMemo(() => {
        if (props.fields) return props.fields
        return [...upstreamFields, ...directFields, ...downstreamFields]
    }, [props.fields, upstreamFields, directFields, downstreamFields])

    if (!product?.uuid) return null

    return (
        <>
            {fields.map((field) => {
                // we're ignoring these fields for now
                if ([LCACode.A5, LCACode.B6, LCACode.B7].includes(field as LCACode)) return null
                const impactValues = product.staticFootprint?.CO2e
                let value = impactValues?.[field as LCACode]
                switch (field) {
                    case UseStageCategoryType.UPSTREAM:
                        value = impactValues?.upstream || product.staticUpstreamCo2e || undefined
                        return
                    case UseStageCategoryType.DIRECT:
                        value = impactValues?.direct || product.staticUpstreamCo2e || product.co2e || undefined
                        return
                    case UseStageCategoryType.DOWNSTREAM:
                        value = impactValues?.downstream || product.staticDownstreamCo2e || undefined
                        return
                }
                if (!impactValues) {
                    if (!value && field === LCACode.A1) {
                        value = product.staticUpstreamCo2e || product.upstreamCo2e || product.co2e || undefined
                    } else if (!value && field === LCACode.B1) {
                        value = product.staticDownstreamCo2e || product.downstreamCo2e || undefined
                    }
                }
                // const lcs = InputService.UseStageByCode.get(field as UseStageCategoryCode)
                let label: string = field
                if (label === UseStageCategoryType.A1_A3) {
                    label = 'A1-A3'
                } else if (labels.has(field)) {
                    label = `${label}: ${labels.get(field)}`
                }
                return (
                    <StaticValueField
                        label={label}
                        key={field}
                        field={field}
                        product={product}
                        placeholder={`Documented ${field} CO2e`}
                        value={value}
                        disabled={props.disabled || isSynced}
                        onChange={(newValue) => {
                            props.onChange({
                                staticFootprint: {
                                    CO2e: { ...product.staticFootprint?.CO2e, [field]: newValue },
                                },
                            })
                        }}
                    />
                )
            })}
        </>
    )
}

export const StaticValueField = (
    props: StandardAttributes & {
        label?: ReactNode
        field?: string
        product?: Product
        placeholder?: string
        value?: string | null
        onChange: (newValue: string) => void
    },
) => {
    return (
        <>
            {props.field === LCACode.A1 && (
                <div className='bg-light p-2 mx--2' style={{ gridColumn: 'key / span 2' }}>
                    Upstream
                </div>
            )}
            {props.field === LCACode.A3 && (
                <div className='bg-light p-2 mx--2' style={{ gridColumn: 'key / span 2' }}>
                    Direct
                </div>
            )}
            {props.field === LCACode.A4 && (
                <div className='bg-light p-2 mx--2' style={{ gridColumn: 'key / span 2' }}>
                    Downstream
                </div>
            )}
            {props.field === LCACode.C1 && (
                <div className='bg-light p-2 mx--2' style={{ gridColumn: 'key / span 2' }}>
                    End-of-life
                </div>
            )}
            {props.field === LCACode.D && (
                <div className='bg-light p-2 mx--2' style={{ gridColumn: 'key / span 2' }}>
                    Optional benefits
                </div>
            )}
            <div className='small' style={{ gridArea: 'auto / key' }}>
                {props.label}
            </div>
            <div
                className='variable-input-group border flex-nowrap bg-light rounded-1'
                style={{ gridArea: 'auto / value' }}
            >
                <InputField
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    defaultValue={props.value || undefined}
                    isNumber={true}
                    numberPrecision={Utils.MAX_DECIMAL_PRECISION}
                    followDefaultValue={true}
                    className='variable-form-control bg-white text-end w-100'
                    onChanged={props.onChange}
                />
                <span className='variable-input-group-text'>kg{Utils.co2e}</span>
            </div>
        </>
    )
}
