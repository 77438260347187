import ProductService, { Product } from '../services/product'
import { Check, IconProps, Plus } from '@phosphor-icons/react'
import Utils from '../services/utils'
import { TooltipProps } from './Tooltip'
import { useElementCreator } from '../hooks/useElementCreator'
import React, { useCallback, useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import { VariableServicesContext } from '../services'
import { InventoryService } from '../services/inventory'
import AuthenticationService from '../services/authentication'
import Button from './Input/Button'

export const InventoryToggle = (props: {
    product?: Product
    showOnProduct?: boolean
    tooltipProps?: Partial<TooltipProps>
    iconProps?: Partial<IconProps>
    onCreated?: (product: Product) => void
}) => {
    const context = useContext(ApplicationContext)
    const { userService, productService, partService, companyService } = useContext(VariableServicesContext)

    const instanceId = useMemo(() => `db-cef-${props.product?.uuid}`, [props.product?.uuid])

    const { createElementFrom } = useElementCreator({ onCreated: props.onCreated })

    const inInventory = useMemo(
        () => InventoryService.getById(props.product?.uuid) !== undefined,
        [context.stores.inventory?.updated, props.product?.uuid],
    )

    const isProduct = useMemo(() => companyService.isMyCompany(props.product?.productOf), [props.product?.productOf])

    const icon = useMemo(() => {
        if (isProduct) {
            return <Check {...props.iconProps} />
        } else if (inInventory) {
            return <Check {...props.iconProps} />
        } else {
            return <Plus color={Utils.successColor} {...props.iconProps} />
            // return <InventoryIcon allInventory={true} />
        }
    }, [isProduct, inInventory])

    const tooltip = useMemo(() => {
        if (!context.stores.user?.uuid) {
            return `Sign in to add to ${InventoryService.webTitle()}`
        } else if (isProduct) {
            return `Your ${ProductService.webTitle().toLowerCase()}`
        } else if (inInventory) {
            return `In your ${InventoryService.webTitle()}`
        } else {
            return `Add to ${InventoryService.webTitle()}`
        }
    }, [isProduct, inInventory, context.stores.user?.uuid])

    const className = useMemo(() => {
        const _className = ['d-inline-block btn btn-plain px-1 rounded-1']
        if (isProduct) {
            _className.push('btn-light')
        } else if (inInventory) {
            _className.push('text-white btn-secondary')
        } else {
            _className.push('border btn-light')
        }
        return _className.join(' ')
    }, [isProduct, inInventory])

    const onClick = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            if (!props.product?.uuid || isProduct) return
            if (!context.stores.user?.uuid) {
                AuthenticationService.sendUserToAuth()
                return
            }
            const isEmissionFactor = ProductService.isEmissionFactor(props.product)
            if (inInventory) {
                if (!isEmissionFactor) {
                    partService.removeSource(props.product?.uuid)
                } else {
                    productService.openPreview(props.product)
                }
            } else {
                if (!isEmissionFactor) {
                    partService.updateSources(props.product)
                } else {
                    createElementFrom({ id: props.product?.uuid, instanceId })
                }
            }
        },
        [props.product?.uuid, context.stores.user?.uuid, inInventory, isProduct, instanceId],
    )

    if (!props.product || userService.isReadonly()) return null

    if (isProduct && props.showOnProduct === false) return null

    return (
        <span title={tooltip}>
            <Button className={className} onClick={onClick}>
                {icon}
            </Button>
        </span>
    )
}
