import Button from './Input/Button'
import { useMemo } from 'react'
import { StandardAttributes } from '../types'

export const Paginator = (
    props: StandardAttributes & {
        currentPage: number
        pageCount: number
        setCurrentPage: (page: number) => void
    },
) => {
    const middlePage = useMemo(() => (props.currentPage > 2 ? props.currentPage - 2 : 1), [props.currentPage])

    const middles = useMemo(() => {
        if (props.pageCount < 2) return null

        let _middles = props.pageCount > 5 ? 5 : props.pageCount
        const remaining = props.pageCount - props.currentPage
        if (props.pageCount > 3 && remaining < 2) {
            _middles = 3 + (remaining > -1 ? remaining : 0)
        }

        return new Array(_middles).fill(middlePage).map((_page, idx) => {
            let pg = middlePage + idx
            return (
                <Button
                    key={`page-${idx}`}
                    className={['btn btn-sm', pg === props.currentPage ? 'btn-primary' : 'btn-light border'].join(' ')}
                    onClick={() => props.setCurrentPage(pg)}
                >
                    {pg}
                </Button>
            )
        })
    }, [props.pageCount, props.currentPage, middlePage])

    const previousButton = useMemo(
        () => (
            <Button
                disabled={props.currentPage === 1}
                className='btn btn-sm btn-light border'
                onClick={() => {
                    const newPage = props.currentPage - 1
                    props.setCurrentPage(newPage || 1)
                }}
            >
                Previous
            </Button>
        ),
        [props.currentPage, props.setCurrentPage],
    )

    const nextButton = useMemo(
        () => (
            <Button
                disabled={props.currentPage === props.pageCount}
                className='btn btn-sm btn-light border'
                onClick={() => {
                    let newPage = props.currentPage + 1
                    if (newPage > props.pageCount) {
                        newPage = props.pageCount
                    }
                    props.setCurrentPage(newPage)
                }}
            >
                Next
            </Button>
        ),
        [props.currentPage, props.pageCount, props.setCurrentPage],
    )

    if (props.pageCount < 2) return null

    return (
        <div
            className={[props.className || 'd-flex justify-content-center w-100', props.extraClassName].join(' ')}
            style={props.style}
        >
            <div>
                <div className='input-group input-group-sm'>
                    {previousButton}
                    {middles}
                    {nextButton}
                </div>
            </div>
        </div>
    )
}
