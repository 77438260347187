import VariableService from './service'
import { UseStageCategoryType } from './useStage'
import { VariableBaseNode } from '../types'

export enum ImpactMethod {
    EN15804A2_Additional = 'EN15804+A2 - Additional impact categories and indicators',
    EN15804A2_Core = 'EN15804+A2 - Core impact categories and indicators',
    EN15804A2_Biogenic = 'EN15804+A2 - Indicators describing biogenic carbon content at factory gate',
    EN15804A2_OutputFlows = 'EN15804+A2 - Indicators describing output flows',
    EN15804A2_ResourceUse = 'EN15804+A2 - Indicators describing resource use',
    EN15804A2_Waste = 'EN15804+A2 - Indicators describing waste categories',
    TRACI21 = 'TRACI v2.1',
}

export enum ImpactCategory {
    ClimateChange = 'Climate Change',
    AirQuality = 'Air Quality',
    WaterQuality = 'Water Quality',
    LandUse = 'Land Use',
    Biodiversity = 'Biodiversity',
    HumanHealth = 'Human Health',
    ResourceDepletion = 'Resource Depletion',
    Waste = 'Waste',
    Social = 'Social',
    Economic = 'Economic',
    Other = 'Other',
}

export enum ImpactIndicator {
    GWP100 = 'GWP100', // Global Warming Potential 100 years
    GWP = 'GWP', // Global Warming Potential
    AP = 'AP', // Acidification Potential
    EP = 'EP', // Eutrophication Potential
    POCP = 'POCP', // Photochemical Ozone Creation Potential
}

export enum ImpactUnit {
    kgCO2e = 'kgCO2e', // Kilogram CO2 equivalent
    kgCFC11e = 'kgCFC11e', // Kilogram CFC-11 equivalent
    kgSO2e = 'kgSO2e', // Kilogram SO2 equivalent
    kgPO4e = 'kgPO4e', // Kilogram PO4 equivalent
    kgNOxe = 'kgNOxe', // Kilogram NOx equivalent
    kgPM10e = 'kgPM10e', // Kilogram PM10 equivalent
    kgCH4e = 'kgCH4e', // Kilogram CH4 equivalent
}

export enum ImpactType {
    Static = 'static',
    Live = 'live',
}

export enum ImpactMeasurementType {
    Intensity = 'intensity',
    Total = 'total',
}

export interface Footprint {
    totalCarbonFootprint?: string
    productCarbonFootprint?: string
    upstream?: string
    direct?: string
    downstream?: string
    A1_A3?: string
    A1?: string
    A2?: string
    A3?: string
    A4?: string
    A5?: string
    B1?: string
    B2?: string
    B3?: string
    B4?: string
    B5?: string
    B6?: string
    B7?: string
    C1?: string
    C2?: string
    C3?: string
    C4?: string
    D?: string
}

export interface FootprintSummary {
    CO2e?: Footprint
}

export interface Impact extends VariableBaseNode, Footprint {
    method: ImpactMethod
    category: ImpactCategory
    indicator: ImpactIndicator
    unit: ImpactUnit
    type: ImpactType
}

export interface ImpactResult {
    category: ImpactCategory // Climate Change
    indicator: ImpactIndicator // GWP
    unit: ImpactUnit // kgCO2e

    measurementType: ImpactMeasurementType // 'total'
    quantity: number // 8.41
    lifecycleStage: UseStageCategoryType // Upstream
}

export interface ImpactSummary {
    gwp: {
        total: {
            up: number | null
            down: number | null
            total: number | null
        }
        market?: {
            up: number | null
            down: number | null
            total: number | null
        }
        intensity: {
            up: number | null
            down: number | null
            total: number | null
        }
    }
}

export default class ImpactService extends VariableService {
    private basePath: string = '/impact'

    public static lcaUpstream = ['A1', 'A2']
    public static lcaDirect = ['A3']
    public static lcaDownstream = ['A4', 'A5', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'C1', 'C2', 'C3', 'C4', 'D']
    public static lcaA1A3 = ['A1', 'A2', 'A3']
    public static lcaCodes = this.lcaUpstream.concat(this.lcaDirect, this.lcaDownstream)
    public static impactTotals = [
        'totalCarbonFootprint',
        // 'productCarbonFootprint',
        'A1_A3',
        'upstream',
        'direct',
        'downstream',
    ]
    public static impactValues = this.impactTotals.concat(this.lcaCodes)

    public static defaultMethods = [
        ImpactMethod.EN15804A2_Additional,
        ImpactMethod.EN15804A2_Core,
        ImpactMethod.EN15804A2_Biogenic,
        ImpactMethod.EN15804A2_OutputFlows,
        ImpactMethod.EN15804A2_ResourceUse,
        ImpactMethod.EN15804A2_Waste,
    ]

    public async getAll(): Promise<ImpactResult[]> {
        return this.httpService.get<ImpactResult[]>(this.basePath).then((impacts) => {
            return impacts
        })
    }

    public async getImpact(nodeId?: string, token?: string): Promise<Impact[]> {
        if (!nodeId) return Promise.reject('No node id')
        const _qs = new URLSearchParams()
        if (token) _qs.set('token', token)
        const qs = _qs.size ? `?${_qs.toString()}` : ''
        return this.httpService.get<Impact[]>(`${this.basePath}/${nodeId}${qs}`)
    }
}
