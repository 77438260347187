import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Utils from '../services/utils'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ProductService, { Product, ProductVisibility } from '../services/product'
import CO2e from '../components/CO2e'
import Spinner from '../components/Spinner'
import AuthenticationService from '../services/authentication'
import usePaginate, { QueryParam } from '../hooks/usePaginate'
import Button from '../components/Input/Button'
import { Img } from '../components/Img'
import { AuthButton } from '../components/Auth0'
import { Passport } from '../components/Passport'
import { VerifiedIcon } from '../components/Icons/VerifiedIcon'
import { ApplicationContext } from '../context'
import { VariableServicesContext } from '../services'
import { TaxonomySidebar } from '../components/TaxonomySidebar'
import { ProductSource } from '../components/Product/ProductSource'
import { Inventory, InventoryService } from '../services/inventory'
import { LifecycleBar } from '../components/Product/LifecycleBar'
import { CO2eDisplay, ND, QueryOptions, StandardAttributes } from '../types'
import { InventoryToggle } from '../components/InventoryToggle'
import TaxonomyService from '../services/taxonomy'
import LocationService from '../services/location'
import { Filters } from '../components/Input/Filters'
import { DataSource } from '../services/dataSource'
import { FileIcon } from '../components/Input/FileIcon'
import { TagList } from '../components/TagList'
import { SortSelector } from '../components/SortSelector'
import { Paginator } from '../components/Paginator'
import { SearchBox } from '../components/Input/SearchBox'
import PartService from '../services/part'
import TransportService from '../services/transport'
import { TransportIcons } from '../components/Icons/TransportIcons'
import { PartIcon } from '../components/Icons/PartIcon'
import { InventoryIcon } from '../components/Icons/InventoryIcon'

let dbTimer: NodeJS.Timeout | undefined = undefined
let fetchTimer: NodeJS.Timeout | undefined = undefined

const DatabasePage = () => {
    const { id } = useParams()
    const context = useContext(ApplicationContext)
    const { uiService, productService, taxonomyService } = useContext(VariableServicesContext)
    const location = useLocation()
    const navigate = useNavigate()
    const [qs] = useSearchParams()
    const [dbPath, setDbPath] = useState<string>()
    const [searchTerm, setSearchTerm] = useState<string>(qs.get('t') || '')
    const [searching, setSearching] = useState<boolean>(false)
    const [emissionFactors, setEmissionFactors] = useState<Product[]>()
    const [queryOptions, setQueryOptions] = useState<QueryOptions>()
    const [ready, setReady] = useState<boolean>(true)
    const { pageCount, currentPage, setCurrentPage, setTotalCount, queryString, setQueryParams } = usePaginate()
    const inputRef = useRef<any>()

    const currentTaxonomy = useMemo(
        () => TaxonomyService.getById(queryOptions?.taxonomy) || null,
        [queryOptions?.taxonomy, context.stores.ui?.taxonomyReady],
    )

    const currentTaxonomyString = useMemo(() => taxonomyService.getTaxonomyString(currentTaxonomy), [currentTaxonomy])

    useEffect(() => {
        if (!location.pathname.startsWith(ProductService.webRootDatabase)) {
            return
        }
        const path = location.pathname.replace(ProductService.webRootDatabase, '').substring(1)
        if (path !== dbPath) {
            setDbPath(path)
        }
        const tx = TaxonomyService.all.find((t) => t.path === path)
        setQueryOptions((state) => ({ ...state, taxonomy: tx?.uuid }))
    }, [location, context.stores.ui?.taxonomyReady])

    useEffect(() => {
        uiService.setTitle(ProductService.databaseTitle, 'Database')
        uiService.showLoggedOutSidebar(true)
        taxonomyService.get().then()

        return () => {
            uiService.showLoggedOutSidebar(false)
        }
    }, [])

    useEffect(() => {
        setReady(true)
        fetchFootprintData()
    }, [context.stores.user, context.stores.company])

    useEffect(() => {
        if (!ready) {
            const st = qs.get('t') || ''
            setSearchTerm(st)
            if (inputRef.current) {
                inputRef.current.value = st
            }
            return
        }
        if (currentTaxonomy?.uuid) {
            uiService.setTitle(currentTaxonomyString, 'Database')
        }
    }, [ready, currentTaxonomy, currentTaxonomyString])

    useEffect(() => {
        if (context.stores.products.productId) {
            const p = InventoryService.getById(context.stores.products.productId)?.originalProduct
            setQueryOptions((state) => ({ ...state, taxonomy: p?.taxonomy?.uuid }))
        }
    }, [context.stores.products.productId])

    useEffect(() => {
        if (dbTimer) {
            clearTimeout(dbTimer)
        }
        if (location.pathname === '/') {
            navigate({ pathname: ProductService.webRootDatabase }, { replace: true })
        }
        dbTimer = setTimeout(() => {
            setQueryParams({ t: searchTerm || undefined }, true)
        }, 300)
    }, [searchTerm])

    useEffect(() => {
        if (searchTerm || currentTaxonomy || AuthenticationService.isAuthenticated) {
            fetchFootprintData()
        } else {
            setEmissionFactors([])
            setTotalCount(0)
        }
    }, [qs, queryString, currentTaxonomy])

    const fetchFootprintData = useCallback(() => {
        if (fetchTimer) {
            clearTimeout(fetchTimer)
        }
        fetchTimer = setTimeout(() => {
            const searchQs = new URLSearchParams(queryString)
            searchQs.set('db', 'true')
            searchQs.delete('when')
            searchQs.delete('year')
            searchQs.delete('startDate')
            searchQs.delete('endDate')
            searchQs.delete('sd')
            searchQs.delete('ed')
            if (currentTaxonomy?.uuid) {
                searchQs.set('taxonomy', currentTaxonomy.uuid)
            }
            if (!searchTerm && !currentTaxonomy && !searchQs.has('orderBy')) {
                searchQs.set('orderBy', 'popularity')
            }
            setSearching(true)
            productService.search({ searchTerm, queryString: searchQs.toString() }).then((results) => {
                if (inputRef.current?.value && results.query !== inputRef.current?.value) {
                    return
                }
                setEmissionFactors(results.data)
                setTotalCount(results.count)
                setSearching(false)
            })
        }, 100)
    }, [queryString, searchTerm, currentTaxonomy])

    const extraDataSourcesMap = useMemo(() => {
        const eds = new Map<string, DataSource>()
        emissionFactors?.forEach((i) => {
            if (i.dataSources) i.dataSources.forEach((ds) => eds.set(ds.uuid!, ds))
            if (i.productOf?.uuid) eds.set(i.productOf.uuid!, i.productOf as DataSource)
        })
        return eds
    }, [emissionFactors])

    const filterQueryOptions: QueryOptions = useMemo(() => {
        const _qs = new URLSearchParams(queryString)
        _qs.delete('t')
        return Object.fromEntries(_qs) as QueryOptions
    }, [queryString])

    const filters = useMemo(
        () => (
            <Filters
                extraClassName='mt-1'
                filters={['source', 'unitType']}
                showDepthFilter={false}
                showFilterSelector={false}
                dataSourceSelectorProps={{
                    showAllSources: !extraDataSourcesMap.size,
                    showPublicSources: true,
                    extraSources: Array.from(extraDataSourcesMap.values()),
                }}
                queryOptions={filterQueryOptions}
                onChange={(newValue) => setQueryParams(newValue as QueryParam)}
                onSet={(newValue) => setQueryParams(newValue as QueryParam)}
            />
        ),
        [extraDataSourcesMap, filterQueryOptions],
    )

    const sorting = useMemo(
        () => (
            <SortSelector
                queryString={queryString}
                className='btn btn-xs ms-auto'
                options={productService.getDatabaseSortOptions()}
                onSelect={(orderBy, orderDir) => setQueryParams({ orderBy, orderDir })}
            />
        ),
        [queryOptions],
    )

    if (!ready) return <Spinner />

    return (
        <div className='d-flex gap-3 max-w-100 max-h-100'>
            <div
                className='bg-light p-2 w-25 flex-grow-0 d-none d-md-flex flex-column gap-2'
                style={{
                    borderTopLeftRadius: '0.5rem',
                    borderBottomLeftRadius: '0.5rem',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                }}
            >
                <div style={{ minHeight: '78vh' }} className='flex-grow-1 overflow-y-auto'>
                    <TaxonomySidebar
                        currentTaxonomy={currentTaxonomy}
                        onSelect={(selectedTaxonomy) => navigate({ pathname: selectedTaxonomy.path })}
                        filterInputClassName='variable-form-control bg-white w-100'
                    />
                </div>
                <div className='alert bg-white border'>
                    <div className='small'>Can't find what you're looking for?</div>
                    <Button
                        onClick={() => uiService.showRequestFootprintForm(true)}
                        className='mt-1 btn btn-sm btn-secondary shadow-none'
                    >
                        Request footprint
                    </Button>
                </div>
            </div>
            <div className='flex-grow-1 w-75 max-h-100 d-flex flex-column'>
                <fieldset className={[!emissionFactors?.length ? '' : 'mb-4', 'flex-shrink-0'].join(' ')}>
                    <span className='d-block position-relative'>
                        <SearchBox
                            extraClassName='p-3 rounded-5'
                            searchIconProps={{ size: Utils.smallIconSize }}
                            clearIconProps={{ size: Utils.smallIconSize }}
                            inputFieldProps={{
                                id: 'db-search',
                                passedRef: inputRef,
                                focusOnRender: true,
                                defaultValue: searchTerm,
                                placeholder: currentTaxonomy?.uuid
                                    ? `Search within ${currentTaxonomyString}`
                                    : 'Search for Footprints of Products & Services',
                                onChange: setSearchTerm,
                            }}
                        />
                    </span>
                    <div className='d-flex align-items-center justify-content-between gap-2'>
                        {filters}
                        {sorting}
                    </div>
                    {!id && currentTaxonomy?.uuid && searchTerm && (
                        <Link
                            to={`${ProductService.webRootDatabase}?t=${searchTerm}`}
                            className='alert alert-info border border-info d-block p-2 mt-2 mx-5'
                        >
                            You are searching within the category {currentTaxonomy?.name}.
                            <span className='d-block underline-on-hover'>Click here to search the entire database</span>
                        </Link>
                    )}
                </fieldset>
                {id && <Passport className='m-5' productId={id} />}
                {!id && (
                    <div className='overflow-y-auto overflow-x-hidden flex-grow-1'>
                        {!searchTerm && !currentTaxonomy && !AuthenticationService.isAuthenticated && (
                            <div className='alert bg-light mt-3 mb-0'>
                                <p>
                                    Footprints represent the greenhouse gas emissions tied to products & services
                                    expressed in CO2 equivalents ({Utils.co2e}).
                                </p>
                                <p>
                                    This database contains Footprints with varying degrees of quality, with industry
                                    average emission factors being the lowest and Live Footprints being the highest.
                                </p>
                                <p>
                                    Variable’s revolutionary Live Footprint format enables you to create up-to-date and
                                    accurate Footprints using specific data from your suppliers and drive effective
                                    decarbonization strategies by gaining granular control over your value chain
                                    emissions.
                                </p>
                                <div className='text-center mt-4'>
                                    <p>
                                        Join Variable and start creating Live Footprints for your products &amp;
                                        services
                                    </p>
                                    <AuthButton className='btn btn-sm btn-secondary shadow-none' screen='signup' />
                                </div>
                            </div>
                        )}
                        {searching && <Spinner />}
                        {!searching &&
                            emissionFactors?.map((ef) => (
                                <DatabaseFootprintItem
                                    key={`dfi-${ef.uuid}`}
                                    ef={ef}
                                    onClick={(e) => {
                                        if (
                                            !AuthenticationService.isOnboarded() &&
                                            ef.visibility <= ProductVisibility.AUTHENTICATED_USERS
                                        ) {
                                            e.preventDefault()
                                            AuthenticationService.sendUserToAuth()
                                        }
                                    }}
                                />
                            ))}
                    </div>
                )}
                {!id && !searching && !!emissionFactors?.length && (
                    <Paginator
                        extraClassName='mt-2 flex-shrink-0'
                        currentPage={currentPage}
                        pageCount={pageCount}
                        setCurrentPage={setCurrentPage}
                    />
                )}
            </div>
        </div>
    )
}

export const DatabaseFootprintItem = (
    props: StandardAttributes & {
        id?: string
        inv?: Inventory
        ef?: Product
        showImage?: boolean
        co2eDisplay?: CO2eDisplay
        description?: ReactNode
        actionButton?: ReactNode
        onClick: (e: any) => void
    },
) => {
    const { productService } = useContext(VariableServicesContext)
    const inv = props.ef ? InventoryService.productToInventory(props.ef) : props.inv
    if (!inv) return null

    const loc = LocationService.getNodeLocation(inv?.originalProduct)

    const sourceFor = inv?.usedIn?.filter((ui) => ui.type === ND.Part)

    let name = (
        <span
            className={[
                'd-flex align-items-center gap-1',
                ProductService.isLiveProduct(inv.originalProduct) ? 'pulsating-circle-pseudo' : '',
            ].join(' ')}
        >
            {inv.originalProduct?.labels?.includes(ND.EPD) && <span className='label-epd'>EPD</span>}
            {ProductService.getProductName(inv)}
            {inv.originalProduct?.isVerified && <VerifiedIcon className='nt--1' />}
            {inv.originalProduct?.isSolution && <span className='label-solution ms-1'>Solution</span>}
            {inv.originalProduct?.quality === 'model' && <span className='label-model ms-1'>Model</span>}
        </span>
    )
    if (inv.originalPart) {
        name = (
            <>
                {PartService.getPartString(inv.originalPart)}: {inv.name || ''}
            </>
        )
    } else if (inv.originalTransportType) {
        name = <>{TransportService.getTransportTypeName(inv.originalTransportType)}</>
    }

    const description = inv.description || ''

    return (
        <Link
            key={`ef-${inv.uuid}`}
            id={props.id}
            className={[props.className || 'item-list d-flex align-items-stretch px-2', props.extraClassName].join(' ')}
            style={{ gridColumn: 'action / span 3', ...props.style }}
            to={inv.url}
            onClick={props.onClick}
        >
            <div
                className='d-flex align-items-center justify-content-center pe-2 flex-shrink-0'
                style={{ minWidth: '3rem' }}
            >
                {props.actionButton || <InventoryToggle product={inv.originalProduct} />}
            </div>
            <div className='d-flex align-items-center gap-2 me-auto'>
                {props.showImage && (
                    <div className='flex-shrink-0'>
                        <Img
                            src={inv.image}
                            size='50px'
                            placeholderIcon={<InventoryIcon size={Utils.largeIconSize} inv={inv} />}
                        />
                    </div>
                )}
                <div className=''>
                    {name}
                    {props.description || (
                        <div
                            className={[
                                'overflow-hidden text-break small text-slightly-muted ps-2 border-start border-3 mt-1',
                                description.length > 350 ? 'gradient-text' : '',
                            ].join(' ')}
                            style={{ maxHeight: '7em' }}
                        >
                            {description}
                        </div>
                    )}
                    {inv.originalTransportType && <TransportIcons transportType={inv.originalTransportType} />}
                    {productService.isPrimary(inv) && (
                        <span className='d-block small mt-1 text-muted'>Primary data</span>
                    )}
                    {inv.originalPart?.uuid && !!inv.originalPart.sourceProductCount && (
                        <div className='text-muted'>
                            {inv.originalPart?.sourceProductCount}{' '}
                            {Utils.pluralize('source', inv.originalPart?.sourceProductCount)}
                        </div>
                    )}
                    <ProductSource inv={inv} />
                    {!!sourceFor?.length && (
                        <span className='d-block small mt-1 text-muted'>
                            {sourceFor?.map((sf) => (
                                <span
                                    key={`inv-sf-${sf.uuid}`}
                                    className='d-inline-block bg-primary bg-opacity-5 px-1 py-0 rounded-1'
                                >
                                    <PartIcon
                                        size={Utils.verySmallIconSize}
                                        part={{ uuid: sf.uuid, name: sf.name, type: sf.partType }}
                                    />{' '}
                                    {sf.name}
                                </span>
                            ))}
                        </span>
                    )}
                    <div className='d-flex mt-2 align-items-center gap-1'>
                        <div hidden={!loc?.uuid} className='border px-1 align-items-center gap-1 small'>
                            {LocationService.getLocationTypeIcon(loc)} {LocationService.getLocationName(loc)}
                        </div>
                        <TagList product={inv.originalProduct} />
                    </div>
                </div>
            </div>
            <div className='text-nowrap ms-3'>
                <CO2e
                    co2e={inv.co2e}
                    part={inv.originalPart}
                    product={inv.originalProduct}
                    co2eDisplay={props.co2eDisplay || 'Total'}
                    functionalUnitClassName='ms-1'
                    unitsClassName='d-block small text-muted'
                    functionalUnit={inv.unit?.code}
                    className='d-block text-end'
                />
                <LifecycleBar className='mt-1' product={inv.originalProduct} co2eDisplay='Total' />
                <div
                    hidden={!inv.originalProduct?.documents?.length}
                    className='mt-1 d-flex align-items-center justify-content-end gap-1'
                >
                    {inv.originalProduct?.documents?.map((doc) => (
                        <div key={`doc-${doc.uuid}`}>
                            <FileIcon file={doc} size={20} extraClassName='text-muted' />
                        </div>
                    ))}
                </div>
            </div>
        </Link>
    )
}

export default DatabasePage
