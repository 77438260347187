import { ActivityActionType, ActivityContext } from '../../services/activityContext'
import OrgService from '../../services/org'
import { ActivityLine } from './ActivityLine'
import Spinner from '../Spinner'
import { CSSProperties, ReactNode, useContext, useMemo } from 'react'
import { BulkActivityEditor } from './BulkActivityEditor'
import { ActivityCo2eView, ActivityItem } from '../../services/activity'
import { Checkbox } from '../Input/Checkbox'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import Utils from '../../services/utils'
import { SortButton } from '../SortButton'
import usePaginate from '../../hooks/usePaginate'
import Button from '../Input/Button'
import { useSearchParams } from 'react-router-dom'
import { useDateOptions } from '../../hooks/useDateOptions'

export type ActivityTableColumnType =
    | 'checkbox'
    | 'submittedBy'
    | 'type'
    | 'input'
    | 'quality'
    | 'quantity'
    | 'company'
    | 'date'
    | 'org'
    | 'scope'
    | 'co2e'
    | 'state'

export const defaultActivityTableColumns: ActivityTableColumnType[] = [
    'checkbox',
    'input',
    'quality',
    'quantity',
    'company',
    'date',
    'org',
    'scope',
    'co2e',
]

export const stateActivityColumns: ActivityTableColumnType[] = [
    'checkbox',
    'submittedBy',
    'input',
    'quantity',
    'org',
    'scope',
    'co2e',
    'state',
]

export const companyActivityColumns: ActivityTableColumnType[] = [
    'input',
    'quality',
    'quantity',
    'date',
    'org',
    'scope',
    'co2e',
]

export type ActivityTableColumn = {
    className?: string
    style?: CSSProperties
    colSpan?: number
    type: ActivityTableColumnType
    text: ReactNode
}

export const ActivityTable = (props: {
    cols?: ActivityTableColumnType[]
    co2eView?: ActivityCo2eView
    showEmptyMessage?: boolean
    spinnerSize?: 'sm' | 'lg'
    filter?: (activity: ActivityItem) => boolean
}) => {
    const context = useContext(ApplicationContext)
    const activityContext = useContext(ActivityContext)
    const [qs] = useSearchParams()
    const { analyticsService, activityService } = useContext(VariableServicesContext)
    const { queryOptions, setQueryParams } = usePaginate()
    const { dateOptions } = useDateOptions()

    const activityTableColumns: ActivityTableColumn[] = useMemo(
        () => [
            {
                type: 'checkbox',
                text: (
                    <Checkbox
                        className='form-check-input'
                        disabled={!activityContext.queryString}
                        hidden={!activityContext.queryString}
                        checked={
                            activityContext.totalCount > 0 &&
                            !!activityContext.activities?.length &&
                            (context.stores.activity.bulkIds.size === activityContext.activities?.length ||
                                context.stores.activity.bulkIds.size === activityContext.totalCount)
                        }
                        onChange={(isChecked) => {
                            if (isChecked) {
                                const newSet = new Set(activityContext.activities?.map((ai) => ai.uuid!))
                                activityService.setBulkIds(newSet)
                                analyticsService.track('Activity Select Page', { count: newSet.size })
                            } else {
                                activityService.resetBulkIds()
                                analyticsService.track('Activity Reset Bulk List')
                            }
                        }}
                    />
                ),
                style: { width: '2rem' },
            },
            {
                type: 'submittedBy',
                text: <SortButton sortKey='submittedBy'>Last change</SortButton>,
                style: { minWidth: '8rem' },
            },
            // { type: 'type', text: 'Type', style: { width: '6rem' } },
            {
                type: 'input',
                text: (
                    <>
                        <SortButton sortKey='input'>Input</SortButton>
                        {activityContext.loading && props.spinnerSize === 'sm' && (
                            <span className='spinner-border spinner-border-sm position-absolute ms-2' />
                        )}
                    </>
                ),
                style: { maxWidth: '50%', minWidth: '12rem' },
            },
            {
                type: 'quality',
                text: <SortButton sortKey='dataQuality'>Data Quality</SortButton>,
                className: 'text-center',
                style: { minWidth: '8rem' },
            },
            {
                type: 'quantity',
                text: <SortButton sortKey='quantity'>Quantity</SortButton>,
                className: 'text-center',
                colSpan: 2,
            },
            {
                type: 'company',
                text: <SortButton sortKey='company'>Company</SortButton>,
            },
            {
                type: 'date',
                text: (
                    <SortButton sortKey='date' isDefault={true} defaultDir='DESC'>
                        Date
                    </SortButton>
                ),
                style: { minWidth: '8rem' },
            },
            {
                type: 'org',
                text: <SortButton sortKey='org'>{OrgService.webTitle()}</SortButton>,
            },
            {
                type: 'scope',
                text: <SortButton sortKey='scope'>Scope</SortButton>,
            },
            {
                type: 'co2e',
                text: (
                    <SortButton sortKey='co2e' arrowPlacement='before'>
                        {context.stores.unitLarge}
                        {Utils.co2e}
                    </SortButton>
                ),
                className: 'text-end',
            },
            {
                type: 'state',
                text: (
                    <SortButton sortKey='state' arrowPlacement='before'>
                        Status
                    </SortButton>
                ),
                className: 'text-end',
            },
        ],
        [
            context.stores.unitLarge,
            activityContext.totalCount,
            activityContext.activities,
            activityContext.loading,
            activityContext.queryString,
            props.spinnerSize,
            context.stores.activity.bulkIds.size,
        ],
    )

    const dateFilter = useMemo(() => {
        const df = dateOptions.find((_do) => _do.value === queryOptions?.when)
        if (queryOptions?.when === 'range') {
            const _startDate = queryOptions?.startDate || queryOptions?.sd
            const _endDate = queryOptions?.endDate || queryOptions?.ed
            return (
                <>
                    {Utils._dayjs(_startDate)?.format('MMM D, YYYY')} - {Utils._dayjs(_endDate)?.format('MMM D, YYYY')}
                </>
            )
        }
        if (queryOptions?.when === 'year' && queryOptions?.year) {
            return queryOptions?.year
        }
        return df?.name
    }, [
        queryOptions?.when,
        queryOptions?.year,
        queryOptions?.startDate,
        queryOptions?.endDate,
        queryOptions?.sd,
        queryOptions?.ed,
        dateOptions,
    ])

    const filterCount = useMemo(() => {
        let fc = qs.size
        if (qs.get('when')) {
            --fc
        }
        if (qs.get('startDate') || qs.get('sd')) {
            --fc
        }
        if (qs.get('endDate') || qs.get('ed')) {
            --fc
        }
        if (qs.get('activityStates')) {
            --fc
        }
        return fc
    }, [qs.size])

    const filterCountMessage = useMemo(() => {
        if (filterCount === 1) {
            return ', and there is one other filter.'
        } else if (filterCount > 1) {
            return `, and there are ${filterCount} other filters.`
        }
        return ''
    }, [filterCount])

    return (
        <div className='position-relative' style={{ minHeight: '5rem' }}>
            <BulkActivityEditor />
            <div className='list-container'>
                <table className='table mb-0'>
                    <thead>
                        <tr className='fw-bold'>
                            {activityTableColumns
                                .filter((atc) => {
                                    if (!props.cols) {
                                        return true
                                    }
                                    return props.cols.includes(atc.type)
                                })
                                .map((atc) => (
                                    <th
                                        key={`col-${atc.type}`}
                                        className={`align-bottom pb-1 small ${atc.className || ''}`}
                                        style={atc.style}
                                        colSpan={atc.colSpan || 1}
                                    >
                                        {atc.text}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody className='align-top'>
                        {context.stores.company?.uuid &&
                            activityContext.activities
                                ?.filter((activity) => props.filter?.(activity) ?? true)
                                ?.map((activity) => (
                                    <ActivityLine
                                        key={`pli-${activity.uuid}`}
                                        cols={props.cols}
                                        co2eView={props.co2eView}
                                        activityItem={activity}
                                        highlight={activityContext.activity?.uuid === activity.uuid}
                                        checked={context.stores.activity.bulkIds.has(activity.uuid || '')}
                                        onClick={(activity) => {
                                            activityContext.dispatch({
                                                type: ActivityActionType.Select,
                                                payload: activity,
                                            })
                                            analyticsService.track('Open Activity Item', { uuid: activity.uuid })
                                        }}
                                        onCheck={(checked) => {
                                            if (checked) {
                                                activityService.addToBulkIds(activity.uuid!)
                                                analyticsService.track('Add Activity to Bulk List', {
                                                    uuid: activity.uuid,
                                                    bulkListSize: context.stores.activity.bulkIds.size,
                                                })
                                            } else {
                                                activityService.removeFromBulkIds(activity.uuid!)
                                                analyticsService.track('Remove Activity from Bulk List', {
                                                    uuid: activity.uuid,
                                                    bulkListSize: context.stores.activity.bulkIds.size,
                                                })
                                            }
                                        }}
                                    />
                                ))}
                        {!activityContext.loading &&
                            props.showEmptyMessage &&
                            activityContext.activities?.length === 0 &&
                            (filterCount > 0 || queryOptions?.when !== 'all') && (
                                <tr>
                                    <td colSpan={activityTableColumns.length} className='align-middle small'>
                                        <p>Not finding what you're looking for?</p>
                                        <p>
                                            The date filter is currently set to {dateFilter}
                                            {filterCountMessage}
                                        </p>
                                        <Button
                                            className='btn btn-sm btn-light border'
                                            onClick={() => {
                                                setQueryParams(
                                                    { when: 'all', activityStates: queryOptions?.activityStates },
                                                    true,
                                                    true,
                                                    true,
                                                )
                                            }}
                                        >
                                            Clear all filters
                                        </Button>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
            {activityContext.loading && props.spinnerSize !== 'sm' && (
                <Spinner className='fill-parent bg-white bg-opacity-25 z-index-fixed' />
            )}
        </div>
    )
}
