import InputService, { Input } from '../../services/input'
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ProductService, { Product } from '../../services/product'
import CO2e from '../CO2e'
import Utils from '../../services/utils'
import { InputItems } from './InputItems'
import InputField from '../Input/InputField'
import Tooltip from '../Tooltip'
import { PrettyNumber } from '../PrettyNumber'
import { LifecycleStageSelector } from './LifecycleStageSelector'
import { Part, PartType } from '../../services/part'
import Button from '../Input/Button'
import { ElementSelector } from './ElementSelector'
import Delete from '../Delete'
import { QualitySummary } from '../User/QualitySummary'
import { ArrowElbowDownRight, CaretRight, CopySimple, GridNine, Lightning, Plus } from '@phosphor-icons/react'
import UnitService, { UnitType } from '../../services/unit'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { useInputType } from '../../hooks/useInputType'
import { InputCalculator } from './InputCalculator'
import { InputTransport } from './InputTransport'
import { InputUseStage } from './InputUseStage'
import { InputProcessing } from './InputProcessing'
import { ModelSelectCell } from '../CategoryModel/ModelSelectCell'
import { DebugData } from '../Footnotes'
import { TransportIcons } from '../Icons/TransportIcons'
import { EtcMenu } from '../EtcMenu'
import { useSave } from '../../hooks/useSave'
import { AmountInput } from '../Input/Amount'
import { Amount, ND } from '../../types'
import { NiceAmount } from '../NiceAmount'
import { InfoIcon } from '../Icons/InfoIcon'
import FlagService, { FlagType } from '../../services/flag'
import { useProduct } from '../../hooks/useProduct'
import { usePart } from '../../hooks/usePart'
import { useInput } from '../../hooks/useInput'
import { useProcessingType } from '../../hooks/useProcessingType'
import { useTransportInstance } from '../../hooks/useTransportInstance'
import { useUseStageType } from '../../hooks/useUseStageType'
import { UseStageCategoryType } from '../../services/useStage'
import { ProductIcon } from '../Icons/ProductIcon'
import Copy from '../Copy'
import { usePermissions } from '../../hooks/usePermissions'

export const InputItem = (props: {
    className?: string
    ariaLabel?: string
    input: Input
    parentInput?: Input
    inputs?: Input[]
    lineType: 'input' | 'transport' | 'processing'
    product?: Product
    position: number
    count: number
    disabled?: boolean
    draggable?: boolean
    dragging?: boolean
    dragHandle?: ReactNode
    dragCount?: number
    onDragStart?: (e: any) => void
    onDragOver?: (e: any) => void
    onDragEnd?: (e: any) => void
    onChange: (input: Input) => void
    onCreate: (input: Input) => void
    onRemoved: (input: Input) => void
}) => {
    const context = useContext(ApplicationContext)
    const {
        uiService,
        productService,
        companyService,
        partService,
        inputService,
        transportService,
        processingService,
        analyticsService,
    } = useContext(VariableServicesContext)
    const [ready, setReady] = useState<boolean>(false)
    const [showPartDetails, setShowPartDetails] = useState<boolean>(false)
    const [highlight, setHighlight] = useState<boolean>(false)
    const [isDuplicating, setIsDuplicating] = useState<boolean>(false)
    const [addingRelatedInput, setAddingRelatedInput] = useState<boolean>(false)
    const [addingTransport, setAddingTransport] = useState<boolean>(false)
    const [addingProcessing, setAddingProcessing] = useState<boolean>(false)
    const [borderStyle, setBorderStyle] = useState<string>('input-type')
    const [childInputs, setChildInputs] = useState<Input[]>([])
    const [etcOpen, setEtcOpen] = useState<boolean>(false)
    const { createTransportInstance, createProcessingType } = useInputType({ instanceId: props.input.uuid || '' })
    const { isSuperAdmin, checkForSuperPowers } = usePermissions()

    const input = useInput({ input: props.input })
    const transportedVia = useInput({ input: input?.transportedVia })
    const processedVia = useInput({ input: input?.processedVia })
    const part = usePart({ part: input?.part })
    const sp = useProduct({ product: input?.sourceProduct })
    const sourceProduct = useMemo(() => {
        if (!input?.sourceProduct?.co2e && input?.part?.uuid && input.part.liveCo2e) {
            return undefined
        }
        return sp
    }, [input?.sourceProduct, input?.part, sp])
    const transportInstance = useTransportInstance({ transportInstance: input?.transportInstance })
    const processingType = useProcessingType({ processingType: input?.processingType })
    const useStageType = useUseStageType({ useStageType: input?.useStageType })

    const inputCo2e = useMemo(() => {
        if (input?.co2e === undefined) return undefined
        return Utils.Decimal(input?.co2e || 0).toNumber()
    }, [input?.co2e])
    const absTotal = useMemo(
        () =>
            props.inputs
                ?.reduce((prev, curr) => {
                    const i = InputService.getById(curr?.uuid) || curr
                    // console.log(i.name, i.co2e)
                    return prev.plus(Utils.Decimal(i.footprint?.CO2e?.totalCarbonFootprint || i?.co2e || 0).abs())
                }, Utils.Decimal(0))
                .toNumber(),
        [props.inputs, context.stores.input.updates],
    )
    const inputCo2ePercent = useMemo(() => {
        const [, inputCo2ePercent] = Utils.percentOfTotal(Math.abs(inputCo2e || 0), absTotal || 0, Utils.MDP)
        return inputCo2ePercent
    }, [inputCo2e, absTotal])
    const qualityClass = useMemo(() => 'text-primary', [])
    const isInputType = useMemo(
        () => !!(useStageType || transportInstance || processingType),
        [useStageType, transportInstance, processingType],
    )

    const updateRelatedElements = useCallback(() => {
        if (input?.product?.uuid) productService.getById(input.product.uuid).then()
        if (input?.part?.uuid) partService.getPart(input.part.uuid).then()
    }, [input?.product?.uuid, input?.part?.uuid])

    useEffect(() => {
        if (!ready) {
            setTimeout(() => setReady(true), 100)
            return
        }
        updateRelatedElements()
    }, [input?.co2e, input?.useStageCategory])

    useEffect(() => {
        if (!ready) return
        if (input?.transportedVia) updateTransportedVia(input).then()
    }, [input?.quantity, input?.unit?.code])

    useEffect(() => {
        if (!context.stores.categoryModel.updates) return
        const modelInstanceNode = context.stores.categoryModel?.instance?.node as Input | Part | undefined
        if (!modelInstanceNode?.uuid) return
        if (modelInstanceNode?.uuid === input?.uuid || modelInstanceNode?.uuid === input?.part?.uuid) {
            if (input?.uuid) inputService.getInput(input?.uuid).then()
        }
    }, [context.stores.categoryModel.updates])

    const childInputIds = useMemo(() => childInputs?.map((i) => i.uuid), [childInputs])

    useEffect(() => {
        if (!input?.uuid) return
        const shouldRefresh = context.stores.input.updatedIds?.some((id) => childInputIds?.includes(id))
        if (shouldRefresh) inputService.getInput(input?.uuid).then()
    }, [context.stores.input.updatedIds])

    useEffect(() => {
        if (!context.stores.input.expandId) return
        if (input?.uuid === context.stores.input.expandId) {
            setShowPartDetails(InputService.expandedById.get(input.uuid) === true)
        }
    }, [context.stores.input.expandId])

    useEffect(() => {
        if (input?.uuid) setHighlight(InputService.highlightedById.get(input.uuid) === true)
    }, [context.stores.input.highlightUpdated])

    useEffect(() => {
        if (!context.stores.input.deletedInputId) return
        if (input?.uuid === context.stores.input.deletedInputId) {
            props.onRemoved(input)
            updateRelatedElements()
            analyticsService.track('Removed Input')
        }
    }, [context.stores.input.deletedInputId])

    const partialSaveFn = useCallback(
        async (properties?: Partial<Input>) => {
            if (!properties) return
            return inputService.updateInput(properties)
        },
        [input?.uuid, input?.co2e, input?.useStageCategory],
    )

    const { pSave, saving } = useSave({
        node: input,
        partialSaveFn,
        onError: () => Utils.errorToast('Could not save'),
    })

    const updateTransportedVia = useCallback(async (i?: Input) => {
        if (!i || i?.unit?.type !== UnitType.WEIGHT) return
        const _transportedVia = InputService.getById(i.transportedVia?.uuid)
        if (!_transportedVia?.uuid || !_transportedVia?.transportInstance?.uuid) {
            return
        }
        const _transportInstance = await transportService.getTransportInstance(
            _transportedVia.transportInstance,
            _transportedVia.uuid,
        )
        await transportService.updateTransportInstance(
            { ..._transportInstance, weight: { quantity: i.quantity, unit: i.unit } },
            _transportedVia?.uuid,
        )
        await inputService.getInput(_transportedVia?.uuid)
    }, [])

    const addTransportButton = useMemo(
        () => (
            <Button
                key={`add-transport-${input?.uuid}`}
                ariaLabel={`Add Transportation for ${input?.name}`}
                disabled={!!transportedVia || props.disabled}
                hidden={!!sourceProduct?.categoryModel?.uuid}
                className='dropdown-item px-1 small text-base-font'
                saving={addingTransport}
                onClick={() => {
                    setAddingTransport(true)
                    createTransportInstance(props.product, props.input).then((ttInput) => {
                        props.onCreate(ttInput)
                        props.onChange({ ...props.input, transportedVia: ttInput })
                        setAddingTransport(false)
                        setAddingRelatedInput(false)
                    })
                }}
            >
                <TransportIcons className='me-1 nt--2' />
                Add Transportation
            </Button>
        ),
        [
            transportedVia,
            addingTransport,
            props.disabled,
            props.product,
            props.input,
            sourceProduct?.categoryModel?.uuid,
        ],
    )

    const addProcessingButton = useMemo(
        () => (
            <Button
                key={`add-processing-${input?.uuid}`}
                ariaLabel={`Add Processing for ${input?.name}`}
                disabled={!!processedVia || props.disabled}
                hidden={!!sourceProduct?.categoryModel?.uuid}
                className='dropdown-item px-1 small text-base-font'
                saving={addingProcessing}
                onClick={() => {
                    setAddingProcessing(true)
                    createProcessingType(props.product, props.input).then((ptInput) => {
                        props.onCreate(ptInput)
                        props.onChange({ ...props.input, processedVia: ptInput })
                        setAddingProcessing(false)
                        setAddingRelatedInput(false)
                    })
                }}
            >
                <Lightning className='me-1 nt--1' />
                Add Processing
            </Button>
        ),
        [
            processedVia,
            addingProcessing,
            props.disabled,
            props.product,
            props.input,
            sourceProduct?.categoryModel?.uuid,
        ],
    )

    const expandable = useMemo(() => {
        let _expandable = false
        if (part?.type === PartType.SWITCH && (sourceProduct || part?.sourceProducts?.length)) {
            _expandable = true
        } else if (!part?.uuid && sourceProduct?.uuid && !sourceProduct.categoryModel) {
            _expandable = true
        }
        return _expandable
    }, [part, sourceProduct])

    const transportBody = useMemo(() => {
        if (!transportInstance) return null
        if (!input?.transportFor) setBorderStyle('input-item')
        return (
            <InputTransport
                input={input}
                disabled={props.disabled}
                dragHandle={props.dragHandle}
                onChange={props.onChange}
            />
        )
    }, [transportInstance, input?.transportFor, input?.quantity, input?.co2e, props.disabled, props.dragHandle])

    const processingBody = useMemo(() => {
        if (!processingType) return null
        if (!input?.processingFor) setBorderStyle('input-item')
        return (
            <InputProcessing
                input={input}
                disabled={props.disabled}
                dragHandle={props.dragHandle}
                onChange={props.onChange}
            />
        )
    }, [processingType, input?.processingFor, props.disabled, input?.quantity, input?.co2e, props.dragHandle])

    const useStageBody = useMemo(() => {
        if (!useStageType) return null
        setBorderStyle('input-item')
        return (
            <InputUseStage
                input={input}
                disabled={props.disabled}
                dragHandle={props.dragHandle}
                onChange={props.onChange}
            />
        )
    }, [useStageType, props.disabled, props.dragHandle])

    const calculationBody = useMemo(() => {
        if (!input?.calculation) return null
        setBorderStyle('input-item')
        return (
            <InputCalculator
                input={input}
                disabled={props.disabled}
                onChange={props.onChange}
                dragHandle={props.dragHandle}
            />
        )
    }, [input, props.disabled, props.dragHandle])

    const actionCell = useMemo(
        () => (
            <td aria-label='Action' className='align-middle'>
                <div className='d-flex align-items-center flex-nowrap'>
                    {props.dragHandle}
                    <Button
                        ariaLabel={`${showPartDetails ? 'Close' : 'Expand'}: ${input?.name || ''}`}
                        className='btn btn-plain shadow-none nt--2'
                        hidden={!expandable}
                        onClick={async () => setShowPartDetails(!showPartDetails)}
                    >
                        <CaretRight
                            className='transition-all-300ms'
                            style={{ transform: showPartDetails ? 'rotate(90deg)' : 'rotate(0deg)' }}
                            size={Utils.verySmallIconSize}
                        />
                    </Button>
                </div>
            </td>
        ),
        [props.dragHandle, expandable, showPartDetails, input?.name],
    )

    const nameCell = useMemo(
        () => (
            <td aria-label='Name' className='align-middle' style={{ width: '12rem' }}>
                <InputField
                    placeholder='Name'
                    className='variable-form-control w-100'
                    disabled={props.disabled}
                    focusOnRender={!input?.name && Utils.justCreated(input)}
                    defaultValue={input?.name}
                    onChanged={(name) => pSave({ name })}
                />
            </td>
        ),
        [input?.name, props.disabled],
    )

    const elementCell = useMemo(() => {
        if (!input) return null
        return (
            <td aria-label='Element' className='align-middle position-relative' style={{ maxWidth: '14rem' }}>
                <ModelSelectCell input={input} field='element' />
                <ElementSelector
                    product={props.product}
                    input={input}
                    disabled={props.disabled}
                    onChange={async (updatedInput, showPartDetails?: boolean) => {
                        await pSave(updatedInput)
                        if (showPartDetails) setShowPartDetails(true)
                    }}
                    onClear={() => {
                        pSave({
                            sourceProduct: null,
                            part: null,
                            useStageType: null,
                            transportInstance: null,
                            processingType: null,
                            modelType: null,
                        }).then(() => props.onChange(input))
                    }}
                />
            </td>
        )
    }, [input, props.product, props.disabled, props.onChange])

    const elementActionCell = useMemo(
        () => (
            <td aria-label='Element Actions' className='align-middle'>
                <div className='d-flex align-items-center justify-content-end gap-1 w-100'>
                    <Tooltip
                        interactive={true}
                        placement='bottom-end'
                        visible={addingRelatedInput}
                        onVisibleChange={setAddingRelatedInput}
                        hidden={!!sourceProduct?.categoryModel || (!!transportedVia?.uuid && !!processedVia?.uuid)}
                        disabled={props.disabled || !input?.uuid}
                        tooltipContent={
                            <>
                                {addTransportButton}
                                {addProcessingButton}
                            </>
                        }
                        className='btn btn-xs bg-light-hover'
                    >
                        <Plus size={Utils.verySmallIconSize} />
                    </Tooltip>
                </div>
            </td>
        ),
        [
            addingRelatedInput,
            addTransportButton,
            addProcessingButton,
            input,
            transportedVia,
            processedVia,
            sourceProduct,
            props.disabled,
        ],
    )

    const amountCell = useMemo(() => {
        if (!input) return null
        const unitType = sourceProduct?.unit?.type || part?.unit?.type
        return (
            <td aria-label='Amount' style={{ width: '5rem' }} className='align-middle position-relative'>
                <ModelSelectCell input={input} field='amount' />
                <div className='d-flex align-items-center justify-content-end'>
                    <AmountInput
                        amount={{
                            quantity: input.quantity,
                            unit: input.unit || (unitType && UnitService.baseUnitByType.get(unitType)),
                        }}
                        disabled={props.disabled || (!sourceProduct?.uuid && !part?.liveCo2e)}
                        inputFieldProps={{ pattern: Utils.numberInputPattern, isNumber: true, required: true }}
                        unitSelectorProps={{ unitType, style: { width: '4rem' } }}
                        onChange={(amount) => pSave({ ...amount })}
                    />
                </div>
            </td>
        )
    }, [input, sourceProduct, part, props.disabled])

    const inputWeightAmount = useMemo(() => {
        if (!input || !input?.unit?.type) return null
        if (!UnitService.unitsWithWeight.includes(input.unit?.type)) return null
        if (!sourceProduct?.weight?.quantity && input.unit.type !== UnitType.WEIGHT) {
            return { quantity: null, unit: input.unit }
        }
        return {
            quantity: InputService.getInputWeight(input, sourceProduct),
            unit: UnitService.unitByCode['kg'],
        } as Amount
    }, [input?.quantity, input?.unit?.code, sourceProduct?.weight, input?.unit?.type])

    const weightCell = useMemo(() => {
        if (!FlagService.enabledFlags.has(FlagType.EnableWeight)) return <td />
        return (
            <td aria-label='Weight' style={{ width: '6rem' }} className='align-middle text-end'>
                {inputWeightAmount?.quantity === null && (
                    <InfoIcon
                        color={Utils.warningColor}
                        tooltipProps={{ interactive: true }}
                        tooltip={
                            <div className='d-flex flex-column align-items-center gap-1 small'>
                                <span>Element does not have weight specified.</span>
                                <Button
                                    className='btn btn-xs btn-primary'
                                    onClick={() => {
                                        if (!sourceProduct) return
                                        productService.openPreview(sourceProduct)
                                    }}
                                >
                                    Set it now
                                </Button>
                            </div>
                        }
                    />
                )}
                {inputWeightAmount?.quantity && (
                    <div className='d-flex align-items-center justify-content-end small'>
                        <NiceAmount
                            amount={inputWeightAmount}
                            prettyNumberProps={{ precision: 1, showZeroesToPrecision: true }}
                        />
                    </div>
                )}
            </td>
        )
    }, [inputWeightAmount])

    const lifecycleStageCell = useMemo(() => {
        let content: ReactNode
        if (sourceProduct?.categoryModel?.uuid || input?.lifecycleStages) {
            // const sp = sourceProduct || part?.emissionFactor?.footprint
            // if (sp?.useStageSummary) {
            //     content = (
            //         <LifecycleBar
            //             product={sp}
            //             className='w-100 mx-2'
            //             co2eDisplay={sp?.categoryModel?.uuid ? 'Total' : 'Upstream'}
            //             percentBarProps={{
            //                 extraClassName: 'bg-dark bg-opacity-10',
            //                 height: '8px',
            //                 showLabels: true,
            //             }}
            //         />
            //     )
            // } else {
            content = (
                <span className='d-block px-2 small'>
                    Multiple{sourceProduct?.categoryModel?.uuid ? '' : ' (A1-A3)'}
                </span>
            )
            // }
        } else {
            content = (
                <LifecycleStageSelector
                    disabled={props.disabled || !input?.uuid}
                    category={input?.useStageCategory}
                    onChange={(useStageCategory) => pSave({ useStageCategory })}
                />
            )
        }
        return (
            <td aria-label='Life-cycle Stage' style={{ width: '8rem' }} className='ps-0 align-middle'>
                <span className='d-flex align-items-center'>{content}</span>
            </td>
        )
    }, [
        input?.uuid,
        input?.useStageCategory,
        input?.lifecycleStages,
        props.disabled,
        sourceProduct?.categoryModel?.uuid,
    ])

    const qualityCell = useMemo(
        () => (
            <td aria-label='Quantity' className='text-center align-middle' style={{ width: '6rem' }}>
                {(isInputType || part?.type === PartType.MIX || (part?.type === PartType.SWITCH && !sourceProduct)) && (
                    <QualitySummary progress={0} value={3} size={30} />
                )}
                {sourceProduct && <QualitySummary product={sourceProduct} size={30} />}
            </td>
        ),
        [part?.type, sourceProduct, isInputType],
    )

    const co2eCell = useMemo(
        () => (
            <td
                aria-label='CO2e'
                className={['text-end small', qualityClass, input?.calculation ? 'align-top' : 'align-middle'].join(
                    ' ',
                )}
            >
                <CO2e
                    co2e={inputCo2e}
                    isPremium={ProductService.isPremium(sourceProduct)}
                    showUnit={false}
                    updating={saving}
                    onZero={sourceProduct?.hasCo2e === false || !sourceProduct?.co2e ? 'dash' : undefined}
                />
            </td>
        ),
        [inputCo2e, sourceProduct, saving, qualityClass, input?.calculation],
    )

    const percentCell = useMemo(
        () => (
            <td
                aria-label='CO2e Percent'
                className={['text-end small', input?.calculation ? 'align-top' : 'align-middle'].join(' ')}
                style={{ width: '6rem' }}
            >
                <PrettyNumber
                    extraClassName={saving ? 'opacity-50' : ''}
                    num={inputCo2ePercent}
                    suffix='%'
                    precision={1}
                    onZero={!sourceProduct?.co2e ? 'dash' : undefined}
                />
            </td>
        ),
        [inputCo2ePercent, saving, sourceProduct?.co2e, input?.calculation],
    )

    const etcCell = useMemo(() => {
        if (!input) return null
        let linkType: ReactNode = null
        let canBundle = false
        if (!FlagService.enabledFlags.has(FlagType.EnableGrouping) || sourceProduct?.categoryModel?.uuid) {
            canBundle = false
        } else if (
            companyService.isMyCompany(sourceProduct?.createdIn || sourceProduct?.productOf) &&
            sourceProduct?.footprint?.CO2e
        ) {
            canBundle = true
        } else if (ProductService.hasLabel(sourceProduct, [ND.EPD, ND.EmissionFactor])) {
            canBundle = true
        }
        if (canBundle) {
            const text: string = input?.lifecycleStages ? 'Bundle (A1)' : 'Unbundle (A1-A3)'
            linkType = (
                <Button
                    ariaLabel={`Link Type: ${input?.name || ''}`}
                    className='dropdown-item px-1 small text-base-font'
                    onClick={() =>
                        pSave({
                            lifecycleStages: input?.lifecycleStages
                                ? null
                                : [UseStageCategoryType.UPSTREAM, UseStageCategoryType.DIRECT],
                        })
                    }
                >
                    <ProductIcon isBundled={!input?.lifecycleStages} /> {text}
                </Button>
            )
        }
        const inputActions: ReactNode[] = []
        if (!sourceProduct?.categoryModel?.uuid && !transportInstance?.uuid && !processingType?.uuid) {
            inputActions.push(addTransportButton)
            inputActions.push(addProcessingButton)
            inputActions.push(
                <Button
                    key={`duplicate-${input?.uuid}`}
                    hidden={!!sourceProduct?.categoryModel?.uuid}
                    saving={isDuplicating}
                    className='dropdown-item px-1'
                    onClick={() => {
                        setIsDuplicating(true)
                        inputService
                            .duplicateInput(input)
                            .then(props.onChange)
                            .then(() => setEtcOpen(false))
                            .then(() => updateRelatedElements())
                            .finally(() => setIsDuplicating(false))
                        analyticsService.track('Clicked Duplicate Input')
                    }}
                >
                    <CopySimple className='me-1 nt--1' />
                    Duplicate
                </Button>,
            )
            inputActions.push(<hr key={`input-action-hr-${input?.uuid}`} className='my-1' />)
        }
        return (
            <td className={['text-end overflow-visible', input?.calculation ? 'align-top' : 'align-middle'].join(' ')}>
                <EtcMenu
                    hidden={props.disabled}
                    disabled={props.disabled}
                    placement='bottom-end'
                    className='bg-light-hover rounded-1 px-1 mx--1'
                    tooltipClassName='text-start'
                    iconProps={{ size: Utils.smallIconSize }}
                    onClick={() => checkForSuperPowers()}
                    visible={etcOpen}
                    onVisibleChange={setEtcOpen}
                >
                    {linkType}
                    {inputActions}
                    {FlagService.enabledFlags.has(FlagType.EnableAllImpactIndicators) && (
                        <Button
                            onClick={() => {
                                setEtcOpen(false)
                                uiService.setImpactNode(props.input)
                            }}
                            className='dropdown-item px-1 small text-base-font'
                        >
                            <GridNine /> Show Impact Indicators
                        </Button>
                    )}
                    {FlagService.enabledFlags.has(FlagType.EnableAllImpactIndicators) && <hr className='my-1' />}
                    <Delete
                        deleteFn={() =>
                            inputService
                                .removeInput(input.uuid)
                                .then(() => undefined)
                                .catch(() => props.onChange(input))
                        }
                        className='dropdown-item px-1'
                    />
                    {input?.syncId && <hr className='my-1' />}
                    {input?.syncId && (
                        <span className='px-2 small'>
                            Sync ID:{' '}
                            <Copy
                                tooltipText='Click to copy Sync ID'
                                value={input.syncId}
                                analyticsData={{ type: 'Input Sync ID' }}
                            />
                        </span>
                    )}
                    <DebugData
                        node={input}
                        className=' '
                        buttonAttrs={{ className: 'dropdown-item px-1 very-small text-muted', extraClassName: ' ' }}
                    />
                </EtcMenu>
            </td>
        )
    }, [
        props.disabled,
        input,
        isSuperAdmin,
        sourceProduct,
        addTransportButton,
        addProcessingButton,
        isDuplicating,
        etcOpen,
        props.onChange,
    ])

    const defaultBody = useMemo(() => {
        if (transportInstance || processingType || useStageType || input?.calculation) return null
        setBorderStyle('input-item')
        return (
            <>
                {actionCell}
                {nameCell}
                {elementCell}
                {elementActionCell}
                {amountCell}
                {weightCell}
            </>
        )
    }, [
        transportInstance,
        processingType,
        useStageType,
        input?.calculation,
        actionCell,
        nameCell,
        elementCell,
        elementActionCell,
        amountCell,
        weightCell,
    ])

    const body = useMemo(() => {
        return transportBody || processingBody || useStageBody || calculationBody || defaultBody
    }, [transportBody, processingBody, useStageBody, calculationBody, defaultBody])

    const byProductItems = useMemo(() => {
        if (!processingType?.byproducts) return null
        return (
            <>
                {processingType?.byproducts?.sort(Utils.sortByCreated)?.map((byproduct) => {
                    const bpCo2e = Utils.Decimal(byproduct.co2e || 0).toNumber()
                    const [, bpPercent] = Utils.percentOfTotal(Math.abs(bpCo2e), absTotal || 0, Utils.MDP)
                    return (
                        <tr
                            key={`byproduct-${byproduct.uuid}`}
                            aria-label={`Byproduct Input: ${byproduct.name}`}
                            className='text-alt-font slim'
                        >
                            <td />
                            <td className='align-middle small text-nowrap' style={{ paddingLeft: '2rem' }}>
                                <span className='d-flex align-items-center text-primary'>
                                    <ArrowElbowDownRight size={Utils.verySmallIconSize} className='nt--4 me-2' />
                                    Output
                                </span>
                            </td>
                            <td className='align-middle small ps-3'>
                                <Button
                                    className='btn btn-xs bg-light-hover border-hover text-start'
                                    onClick={() => {
                                        processingService.editProcessingType({ node: input as Input, byproduct })
                                    }}
                                >
                                    {byproduct.name}
                                </Button>
                            </td>
                            <td />
                            <td className='align-middle'>
                                <AmountInput
                                    amount={byproduct.amount}
                                    disabled={true}
                                    inputFieldProps={{
                                        isNumber: true,
                                        required: true,
                                        extraClassName: 'bg-transparent',
                                    }}
                                    onChange={() => {}}
                                />
                            </td>
                            <td />
                            <td />
                            <td />
                            <td className='align-middle small text-end'>
                                <CO2e co2e={byproduct.co2e} showUnit={false} />
                            </td>
                            <td className='align-middle small text-end'>
                                <PrettyNumber suffix='%' num={bpPercent} precision={1} />
                            </td>
                            <td />
                        </tr>
                    )
                })}
            </>
        )
    }, [input, absTotal])

    const ariaLabel = useMemo(() => {
        if (props.ariaLabel) return props.ariaLabel
        if (useStageType?.uuid) return 'Use Stage'
        return `Input: ${input?.name || 'New Input'}`
    }, [props.ariaLabel, useStageType, input?.name])

    const partDetails = useMemo(() => {
        if (!showPartDetails) return null
        return (
            <tr
                aria-label={`Nested: ${sourceProduct?.name || input?.name || ''}`}
                className='bg-dark bg-opacity-10 border-bottom border-dark border-last-none'
            >
                <td colSpan={12} className='ps-4'>
                    {sourceProduct && (
                        <InputItems
                            nested={true}
                            parentInput={input}
                            product={sourceProduct}
                            part={part}
                            disabled={!sourceProduct?.editable || props.disabled}
                            onInputData={setChildInputs}
                        />
                    )}
                </td>
            </tr>
        )
    }, [showPartDetails, input, part, sourceProduct, props.disabled])

    const transportItem = useMemo(() => {
        if (!transportedVia?.uuid) return null
        return (
            <InputItem
                key={`input-item-${transportedVia?.uuid}`}
                ariaLabel={`Transport for: ${input?.name || ''}`}
                inputs={props.inputs}
                lineType='transport'
                disabled={props.disabled}
                position={props.position + 1}
                product={props.product}
                input={transportedVia}
                parentInput={input}
                count={props.count}
                onChange={props.onChange}
                onCreate={props.onCreate}
                onRemoved={props.onRemoved}
            />
        )
    }, [
        transportedVia,
        input,
        props.inputs,
        props.disabled,
        props.position,
        props.product,
        props.count,
        props.onChange,
        props.onCreate,
        props.onRemoved,
    ])

    const processItem = useMemo(() => {
        if (!processedVia?.uuid) return
        return (
            <InputItem
                key={`input-item-${processedVia?.uuid}`}
                ariaLabel={`Processing for: ${input?.name || ''}`}
                inputs={props.inputs}
                lineType='processing'
                disabled={props.disabled}
                position={props.position + 1}
                product={props.product}
                input={processedVia}
                parentInput={input}
                count={props.count}
                onChange={props.onChange}
                onCreate={props.onCreate}
                onRemoved={props.onRemoved}
            />
        )
    }, [
        processedVia,
        input,
        props.inputs,
        props.disabled,
        props.position,
        props.product,
        props.count,
        props.onChange,
        props.onCreate,
        props.onRemoved,
    ])

    const parentLifecycleStages = useMemo(
        () => props.parentInput?.lifecycleStages || [UseStageCategoryType.UPSTREAM, UseStageCategoryType.DIRECT],
        [props.parentInput],
    )

    const inputIsActive = useMemo(() => {
        if (!props.parentInput) return true
        if (!input?.useStageCategory) return true
        if (input?.product?.uuid === props?.parentInput?.product?.uuid) return true
        return (
            parentLifecycleStages.includes(input?.useStageCategory?.type) ||
            parentLifecycleStages.includes(input?.useStageCategory?.code)
        )
    }, [props.parentInput, input, parentLifecycleStages])

    if (
        !input ||
        (transportInstance && input?.transportFor && props.lineType !== 'transport') ||
        (processingType && input?.processingFor && props.lineType !== 'processing')
    ) {
        return null
    }

    return (
        <>
            <tr
                id={`input-item-${input?.uuid}`}
                key={`input-item-${input?.uuid}`}
                aria-label={ariaLabel}
                draggable={props.dragging}
                onDragStart={(e) => {
                    e.dataTransfer.effectAllowed = 'move'
                    props.onDragStart?.(e)
                }}
                onDragOver={props.onDragOver}
                onDragEnd={props.onDragEnd}
                className={[
                    showPartDetails ? 'bg-dark bg-opacity-10' : '',
                    props.className || '',
                    'text-alt-font text-primary',
                    borderStyle,
                    !inputIsActive ? 'opacity-25' : '',
                    highlight ? 'highlight-input' : '',
                ].join(' ')}
            >
                {body}
                {lifecycleStageCell}
                {qualityCell}
                {co2eCell}
                {percentCell}
                {etcCell}
            </tr>
            {partDetails}
            {transportItem}
            {processItem}
            {byProductItems}
        </>
    )
}
