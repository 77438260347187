import { ButtonHTMLAttributes, ReactNode, RefObject, useMemo } from 'react'
import Tooltip, { TooltipProps } from '../Tooltip'
import { StandardAttributes } from '../../types'

export type ButtonProps = StandardAttributes & {
    id?: string
    passedRef?: RefObject<any>
    element?: 'div' | 'span'
    size?: 'xxs' | 'xs' | 'sm' | 'lg'
    buttonStyle?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'info'
        | 'danger'
        | 'light'
        | 'outline-primary'
        | 'outline-secondary'
        | 'outline-success'
        | 'outline-info'
        | 'outline-danger'
        | 'outline-light'
    buttonVariant?: 'outline' | 'link'
    children?: ReactNode
    spinner?: ReactNode
    tabIndex?: number
    saving?: boolean
    unstyled?: boolean
    tooltip?: string
    tooltipProps?: Partial<TooltipProps>
    disabledTooltip?: ReactNode
    disabledTooltipProps?: Partial<TooltipProps>
    type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
    onClick?: (e: any) => void
    onMouseEnter?: (e: any) => void
    onMouseLeave?: (e: any) => void
    onDoubleClick?: (e: any) => void
}

const Button = (props: ButtonProps) => {
    const spinner = useMemo(() => {
        if (!props.saving) return null
        return props.spinner || <span className='spinner-border spinner-border-sm ms-1' />
    }, [props.saving, props.spinner])

    const className = useMemo(
        () =>
            [
                props.className || 'd-flex align-items-center justify-content-center gap-1 text-nowrap',
                props.element !== undefined && !props.disabled ? 'clickable' : '',
                props.size === 'xxs' ? 'btn-xxs' : '',
                props.size === 'xs' ? 'btn-xs' : '',
                props.size === 'sm' ? 'btn-sm' : '',
                props.size === 'lg' ? 'btn-lg' : '',
                props.buttonStyle ? `btn btn-${props.buttonStyle}` : '',
                props.extraClassName,
            ].join(' '),
        [props.className, props.extraClassName, props.element, props.disabled, props.size, props.buttonStyle],
    )

    const elementProps = useMemo(
        () => ({
            id: props.id,
            tabIndex: props.tabIndex,
            className: className,
            style: props.style,
            hidden: props.hidden,
            disabled: props.disabled || props.saving,
            onClick: props.onClick,
            onDoubleClick: props.onDoubleClick,
            onMouseEnter: props.onMouseEnter,
            onMouseLeave: props.onMouseLeave,
        }),
        [
            props.id,
            props.tabIndex,
            className,
            props.style,
            props.hidden,
            props.disabled,
            props.saving,
            props.onClick,
            props.onDoubleClick,
            props.onMouseEnter,
            props.onMouseLeave,
        ],
    )

    if (props.disabled && props.disabledTooltip) {
        return (
            <Tooltip
                {...props.disabledTooltipProps}
                id={props.id}
                ariaLabel={props.ariaLabel}
                trigger='hover'
                className={`${className} disabled`}
                positioning='fixed'
                style={props.style}
                hidden={props.hidden}
                tooltipContent={props.disabledTooltip}
            >
                {props.children}
            </Tooltip>
        )
    }

    if (props.tooltip) {
        return (
            <Tooltip
                {...props.tooltipProps}
                trigger='hover'
                id={props.id}
                ariaLabel={props.ariaLabel}
                hidden={props.hidden}
                tooltipContent={props.tooltip}
                className={className}
                style={props.style}
                disabled={props.disabled || props.saving}
                onClick={props.onClick}
                onDoubleClick={props.onDoubleClick}
            >
                {props.children}
                {spinner}
            </Tooltip>
        )
    }

    if (props.element === 'div') {
        return (
            <div {...elementProps} aria-label={props.ariaLabel}>
                {props.children}
                {spinner}
            </div>
        )
    }

    if (props.element === 'span') {
        return (
            <span {...elementProps} aria-label={props.ariaLabel}>
                {props.children}
                {spinner}
            </span>
        )
    }

    return (
        <button ref={props.passedRef} type={props.type || 'button'} {...elementProps} aria-label={props.ariaLabel}>
            {props.children}
            {spinner}
        </button>
    )
}

export default Button
