import { SortButton, SortButtonProps, SortKey } from './SortButton'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { OrderDir, StandardAttributes, VariableBaseNode } from '../types'
import { useSearchParams } from 'react-router-dom'
import usePaginate from '../hooks/usePaginate'
import { Paginator } from './Paginator'

export interface TableColumn<T, Y> {
    label?: ReactNode
    sortKey?: SortKey
    showSearchBox?: boolean
    defaultDir?: OrderDir
    isDefault?: boolean
    headerAttrs?: StandardAttributes
    sortButtonAttrs?: SortButtonProps
    cellAttrs?: StandardAttributes
    content: (item: T, other?: Y) => ReactNode
    onItemClick?: (item: T, other?: Y) => void
}

export type TableProps<T, Y> = {
    containerAttrs?: StandardAttributes
    tableAttrs?: StandardAttributes
    headerAttrs?: StandardAttributes
    bodyAttrs?: StandardAttributes
    rowAttrs?: StandardAttributes
    cellAttrs?: StandardAttributes
    columns: TableColumn<T, Y>[]
    data: T[]
    onData?: (data: T[]) => void
    prepareData?: (data: T[]) => Y[]
    onItemClick?: (item: T) => void
    sortFn?: (a: T, b: T) => number
    filterFn?: (item: T) => boolean
}

export function Table<T extends VariableBaseNode, Y extends VariableBaseNode | undefined>(
    props: StandardAttributes & TableProps<T, Y>,
) {
    const [qs] = useSearchParams()
    const [preparedData, setPreparedData] = useState<Y[]>([])
    const orderDir: OrderDir = useMemo(() => (qs.get('orderDir') as OrderDir) || 'ASC', [qs])
    const { searchBox, pageCount, setCurrentPage, currentPage, pageSize, setTotalCount } = usePaginate()

    const sortedData = useMemo(() => {
        let data = [...(props.data || [])]
        if (props.filterFn) data = data.filter(props.filterFn)
        if (props.sortFn) data = data.sort(props.sortFn)
        if (orderDir === 'DESC') data = data.reverse()
        if (props.prepareData) setPreparedData(props.prepareData(data))
        setTimeout(() => props.onData?.(data), 10)
        setTotalCount(data.length)
        const startAt = (currentPage - 1) * pageSize
        const endAt = startAt + pageSize
        return data.slice(startAt, endAt)
    }, [props.data, props.sortFn, props.filterFn, orderDir, currentPage, pageSize])

    return (
        <div
            hidden={props.hidden}
            className={[props.className || 'd-flex flex-column flex-grow-1 h-100', props.extraClassName].join(' ')}
            style={props.style}
        >
            <div
                className={[
                    props.containerAttrs?.className || 'flex-grow-1 overflow-auto',
                    props.containerAttrs?.extraClassName,
                ].join(' ')}
                style={props.containerAttrs?.style}
            >
                <div
                    className={[props.tableAttrs?.className || 'd-table', props.tableAttrs?.extraClassName].join(' ')}
                    style={props.tableAttrs?.style}
                    hidden={props.tableAttrs?.hidden}
                >
                    <div
                        className={[
                            props.headerAttrs?.className || 'd-table-header-group',
                            props.headerAttrs?.extraClassName,
                        ].join(' ')}
                        style={props.headerAttrs?.style}
                        hidden={props.headerAttrs?.hidden}
                    >
                        {props.columns.map((col) => {
                            let content: ReactNode = col.label || <>&nbsp;</>
                            if (col.sortKey) {
                                content = (
                                    <SortButton
                                        sortKey={col.sortKey}
                                        isDefault={col.isDefault}
                                        defaultDir={col.defaultDir}
                                        {...col.sortButtonAttrs}
                                    >
                                        {content}
                                    </SortButton>
                                )
                            } else {
                                content = <span className='fw-bold'>{content}</span>
                            }
                            if (col.showSearchBox) {
                                content = (
                                    <div className='d-flex align-items-center gap-2'>
                                        {content}
                                        {searchBox}
                                    </div>
                                )
                            }
                            return (
                                <div
                                    key={`col-${col.sortKey || col.label}`}
                                    className={[
                                        col.headerAttrs?.className || props.headerAttrs?.className || 'd-table-cell',
                                        col.headerAttrs?.extraClassName || props.headerAttrs?.extraClassName,
                                    ].join(' ')}
                                    style={col.headerAttrs?.style}
                                >
                                    {content}
                                </div>
                            )
                        })}
                    </div>
                    <div
                        className={[
                            props.bodyAttrs?.className || 'd-table-row-group',
                            props.bodyAttrs?.extraClassName,
                        ].join(' ')}
                        style={props.bodyAttrs?.style}
                        hidden={props.bodyAttrs?.hidden}
                    >
                        {sortedData.map((item, idx) => (
                            <div
                                key={`row-${idx}`}
                                className={[
                                    props.rowAttrs?.className || 'd-table-row',
                                    props.rowAttrs?.extraClassName,
                                ].join(' ')}
                                style={props.rowAttrs?.style}
                                hidden={props.rowAttrs?.hidden}
                            >
                                {props.columns.map((col) => (
                                    <TableCell
                                        key={`item-${col.sortKey || col.label}-${item.uuid}`}
                                        tableProps={props}
                                        col={col}
                                        item={item}
                                        other={preparedData[idx]}
                                        onItemClick={props.onItemClick}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Paginator
                className='flex-shrink-0 d-flex align-items-center justify-content-center pt-2'
                currentPage={currentPage}
                pageCount={pageCount}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}

export function TableCell<T, Y>(props: {
    tableProps: TableProps<T, Y>
    col: TableColumn<T, Y>
    item: T
    other: Y
    onItemClick?: (item: T, other?: Y) => void
}) {
    const onClick = useCallback(
        (item?: any) => {
            if (props.col.onItemClick) {
                props.col.onItemClick(item)
            } else {
                props.onItemClick?.(item)
            }
        },
        [props.col.onItemClick, props.onItemClick],
    )
    return (
        <div
            role={props.onItemClick ? 'button' : 'cell'}
            tabIndex={0}
            hidden={props.col.cellAttrs?.hidden}
            className={[
                props.col.cellAttrs?.className || props.tableProps.cellAttrs?.className || 'd-table-cell align-middle',
                props.col.cellAttrs?.extraClassName || props.tableProps.cellAttrs?.extraClassName,
            ].join(' ')}
            style={props.col.cellAttrs?.style}
            onClick={() => onClick(props.item)}
            onKeyDown={(e) => e.key === 'Enter' && onClick(props.item)}
        >
            {props.col.content(props.item, props.other)}
        </div>
    )
}
