import { useCallback, useContext, useEffect, useState } from 'react'
import { ApplicationContext } from '../context'
import { VariableServicesContext } from '../services'
import { SuperPowers } from '../services/user-context'

export const usePermissions = (props?: {
    disabled?: boolean
    setDisabledOnReadonly?: boolean
    checkForSuperPowers?: boolean
}) => {
    const { userService } = useContext(VariableServicesContext)
    const context = useContext(ApplicationContext)
    const [disabled, setDisabled] = useState<boolean>(props?.disabled || false)
    const [readonly, setReadonly] = useState<boolean>(false)
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>()

    useEffect(() => setReadonly(userService.isReadonly()), [context.stores.user?.role?.slug])

    const checkForSuperPowers = useCallback(() => {
        userService.hasSuperPower(SuperPowers.GLOBAL_ADMIN).then(setIsSuperAdmin).catch(console.warn)
    }, [])

    useEffect(() => {
        if (props?.checkForSuperPowers) checkForSuperPowers()
    }, [props?.checkForSuperPowers])

    useEffect(() => {
        if (props?.setDisabledOnReadonly !== false && readonly) {
            setDisabled(readonly)
        } else {
            setDisabled(props?.disabled || false)
        }
    }, [readonly, props?.disabled])

    return { readonly, disabled, setDisabled, checkForSuperPowers, isSuperAdmin }
}
