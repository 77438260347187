import Utils from '../services/utils'
import { useContext, useMemo } from 'react'
import Tooltip from './Tooltip'
import { ApplicationContext } from '../context'
import { StandardAttributes } from '../types'

export const DateString = (
    props: StandardAttributes & {
        date?: string | number | null
        showTime?: boolean
        relativeTime?: boolean
        highlightFuture?: boolean
    },
) => {
    const context = useContext(ApplicationContext)
    const date = useMemo(
        () => (typeof props.date === 'string' ? new Date(props.date).valueOf() : props.date),
        [props.date],
    )

    const isFutureDate = useMemo(
        () => props.highlightFuture && !!date && Utils.dayjs(date).isAfter(Utils.dayjs().endOf('day')),
        [date, props.highlightFuture],
    )

    const content = useMemo(() => {
        if (!date) return null

        let className = props.className || ''
        if (isFutureDate) className += ' border-bottom border-warning border-dotted bg-warning bg-opacity-10'

        if (props.relativeTime) {
            return <span className={className}>{Utils.dayjs().to(date)}</span>
        }

        const dateString = props.showTime ? Utils.toDateTimeString(date) : Utils.toDateString(date)
        return (
            <span className={[className, props.extraClassName].join(' ')} style={props.style}>
                {dateString}
            </span>
        )
    }, [
        date,
        isFutureDate,
        props.className,
        props.extraClassName,
        props.style,
        props.relativeTime,
        props.showTime,
        context.stores.user?.uiPreferences,
    ])

    if (isFutureDate) {
        return (
            <Tooltip trigger='hover' className='d-inline-block' tooltipContent='Date is in the future'>
                {content}
            </Tooltip>
        )
    }

    return content
}
