import { StandardAttributes } from '../types'
import { ReactNode, useMemo } from 'react'
import { PrettyNumber } from './PrettyNumber'

export interface HeaderProps extends StandardAttributes {
    children: ReactNode
    icon?: ReactNode
    description?: ReactNode
    headerAttrs?: StandardAttributes
    count?: number
    action?: ReactNode
    size?: 1 | 2 | 3 | 4 | 5 | 6
}

export const Header = (props: HeaderProps) => {
    const className = useMemo(() => [props.className || 'm-0', props.extraClassName].join(' '), [props.className])

    const count = useMemo(() => {
        if (props.count === undefined) return null
        return <PrettyNumber num={props.count} surround='()' className=' ' />
    }, [props.count])

    const header: ReactNode = useMemo(() => {
        switch (props.size) {
            case 1:
                return (
                    <h1
                        className={[
                            props.headerAttrs?.className || 'd-flex align-items-center gap-1 m-0',
                            props.headerAttrs?.extraClassName,
                        ].join(' ')}
                    >
                        {props.icon}
                        {props.children}
                        {count}
                    </h1>
                )
            case 2:
                return (
                    <h2
                        className={[
                            props.headerAttrs?.className || 'd-flex align-items-center gap-1 m-0',
                            props.headerAttrs?.extraClassName,
                        ].join(' ')}
                    >
                        {props.icon}
                        {props.children}
                        {count}
                    </h2>
                )
            case 3:
                return (
                    <h3
                        className={[
                            props.headerAttrs?.className || 'd-flex align-items-center gap-1 m-0',
                            props.headerAttrs?.extraClassName,
                        ].join(' ')}
                    >
                        {props.icon}
                        {props.children}
                        {count}
                    </h3>
                )
            case 4:
                return (
                    <h4
                        className={[
                            props.headerAttrs?.className || 'd-flex align-items-center gap-1 m-0',
                            props.headerAttrs?.extraClassName,
                        ].join(' ')}
                    >
                        {props.icon}
                        {props.children}
                        {count}
                    </h4>
                )
            case 5:
                return (
                    <h5
                        className={[
                            props.headerAttrs?.className || 'd-flex align-items-center gap-1 m-0 fw-bold',
                            props.headerAttrs?.extraClassName,
                        ].join(' ')}
                    >
                        {props.icon}
                        {props.children}
                        {count}
                    </h5>
                )
            case 6:
                return (
                    <h6
                        className={[
                            props.headerAttrs?.className ||
                                'd-flex align-items-center gap-1 text-uppercase very-small text-muted text-alt-font',
                            props.headerAttrs?.extraClassName,
                        ].join(' ')}
                    >
                        {props.icon}
                        {props.children}
                        {count}
                    </h6>
                )
            default:
                return props.children || null
        }
    }, [props.children, props.icon, className, count])

    const description = useMemo(() => {
        if (!props.description) return null
        return <div className='small text-slightly-muted'>{props.description}</div>
    }, [props.description])

    return (
        <div
            hidden={props.hidden}
            className={[props.className || 'd-flex flex-column align-items-start gap-1', props.extraClassName].join(
                ' ',
            )}
            style={props.style}
        >
            {header}
            {description}
        </div>
    )
}
