import { Modal } from './Modal'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ProductService, { Product } from '../services/product'
import { ApplicationContext } from '../context'
import { VariableServicesContext } from '../services'
import { MiniPassport } from './MiniPassport'
import { ArrowRight, ArrowUp, Database } from '@phosphor-icons/react'
import Utils from '../services/utils'
import InputField from './Input/InputField'
import Button from './Input/Button'
import { InventoryIcon } from './Icons/InventoryIcon'
import { InventoryService } from '../services/inventory'
import { Link } from 'react-router-dom'

export const ElementCreator = () => {
    const context = useContext(ApplicationContext)
    const { productService, inventoryService } = useContext(VariableServicesContext)
    const [createFrom, setCreateFrom] = useState<Product>()
    const [loading, setLoading] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)
    const [name, setName] = useState<string>()
    const [notes, setNotes] = useState<string>()

    useEffect(() => {
        if (!context.stores.inventory.createFrom?.id) {
            setCreateFrom(undefined)
            return
        }
        setLoading(true)
        productService
            .getById(context.stores.inventory.createFrom.id, true, true)
            .then(setCreateFrom)
            .finally(() => setLoading(false))
    }, [context.stores.inventory.createFrom?.id])

    useEffect(
        () => setName(context.stores.inventory.createFrom?.properties?.name || createFrom?.name),
        [createFrom?.name, context.stores.inventory.createFrom?.properties?.name],
    )

    const reset = useCallback(() => {
        setName(undefined)
        setNotes(undefined)
        setSaving(false)
        setLoading(false)
        inventoryService.setCreateFrom(undefined)
    }, [])

    const createElement = useCallback(
        async (product: Product) => {
            if (!name) return
            setSaving(true)
            const newProduct = await productService.createProductFrom(product, { name, notes }).catch(Utils.errorToast)
            if (newProduct) {
                reset()
                inventoryService.setCreatedFrom(newProduct)
                Utils.successToast(
                    <div className='d-flex flex-wrap align-items-center gap-1 flex-wrap text-height-1'>
                        Added
                        <Link
                            to={ProductService.getProductUrl(newProduct)}
                            className='text-decoration-underline'
                            onClick={() => Utils.dismissToast()}
                        >
                            {newProduct.name}
                        </Link>
                        to {InventoryService.webTitle()}
                    </div>,
                    { autoClose: 5000 },
                )
            }
            setSaving(false)
        },
        [name, notes],
    )

    const content = useMemo(() => {
        if (loading) {
            return (
                <div className='text-center py-2'>
                    <span className='spinner-border spinner-border-sm' />
                </div>
            )
        }
        return (
            <div className='row gap-2'>
                <div className='col order-md-3'>
                    <InventoryIcon /> Inventory
                    <InputField
                        placeholder={name || createFrom?.name || 'Name'}
                        focusOnRender={true}
                        defaultValue={name || createFrom?.name || ''}
                        className='variable-form-control bg-light border w-100 fs-6 mt-2'
                        onChange={setName}
                    />
                    <InputField
                        placeholder='Notes'
                        multiLine={true}
                        rows={3}
                        className='variable-form-control bg-light border w-100 mt-2'
                        onChange={setNotes}
                    />
                </div>
                <div className='col-12 col-md-auto align-self-center order-md-2'>
                    <ArrowRight className='d-none d-md-block' />
                    <ArrowUp className='d-block d-md-none mx-auto' />
                </div>
                <div className='col-12 col-md col-md-4 order-md-1'>
                    <Database /> Database
                    <MiniPassport product={createFrom} co2eDisplay='Total' className='mt-2' />
                </div>
            </div>
        )
    }, [createFrom, name, notes, loading])

    if (!createFrom && !loading) return null

    return (
        <Modal
            hidden={!createFrom && !loading}
            size='lg'
            contentStyle={{ minHeight: '30vh' }}
            onVisibilityChange={(v) => !v && reset()}
            header={
                <>
                    Create new {ProductService.elementTitle()} in the {InventoryService.webTitle()}
                </>
            }
            content={content}
            footer={
                <Button
                    disabled={!name}
                    saving={saving}
                    className='btn btn-sm btn-secondary'
                    onClick={() => createFrom && createElement(createFrom)}
                >
                    Create
                </Button>
            }
        />
    )
}
