import { TransportSegmentEditor } from './TransportSegmentEditor'
import Button from '../Input/Button'
import { Link, useNavigate } from 'react-router-dom'
import ActivityService from '../../services/activity'
import Utils from '../../services/utils'
import CO2e from '../CO2e'
import TransportService, { TransportType } from '../../services/transport'
import { CSSProperties, useCallback, useContext, useState } from 'react'
import { VariableServicesContext } from '../../services'
import Delete from '../Delete'
import { EtcMenu } from '../EtcMenu'
import { Footnotes } from '../Footnotes'
import InputField from '../Input/InputField'
import UnitService from '../../services/unit'
import { StandardAttributes } from '../../types'
import { TransportTypeLinks } from './TransportTypeLinks'
import { NiceAmount } from '../NiceAmount'
import { ArrowsOutSimple, Plus } from '@phosphor-icons/react'
import { InventoryService } from '../../services/inventory'
import ProductService from '../../services/product'
import { useTransportType } from '../../hooks/useTransportType'
import { ImpactTable } from '../ImpactTable'

const TransportDetail = (
    props: StandardAttributes & {
        transportType?: TransportType
        segmentListStyle?: CSSProperties
        isEmbedded?: boolean
        showDelete?: boolean
        onSaving?: () => void
        onSave?: (transportType: TransportType) => void
        onDone?: () => void
    },
) => {
    const { transportService } = useContext(VariableServicesContext)
    const navigate = useNavigate()
    const [saving, setSaving] = useState<boolean>(false)

    const transportType = useTransportType({ transportType: props.transportType })

    const save = useCallback(
        (properties: Partial<TransportType>) => {
            if (!transportType) return
            setSaving(true)
            props.onSaving?.()
            transportService
                .saveTransportType({ ...transportType, ...properties })
                .then(props.onSave)
                .catch(Utils.errorToast)
                .finally(() => setSaving(false))
        },
        [transportType],
    )

    return (
        <div className={props.className} style={props.style}>
            <div className='d-flex justify-content-between gap-2 border-bottom pb-2 mb-2'>
                <InputField
                    placeholder={TransportService.getTransportTypeName(transportType, 'Transport lane name')}
                    className='variable-form-control-minimal'
                    defaultValue={transportType?.name}
                    focusOnRender={!transportType?.name}
                    onChanged={(name) => save({ name })}
                />
                <div
                    className={[
                        props.isEmbedded && props.showDelete === false ? 'd-none' : 'd-flex',
                        'align-items-center',
                    ].join(' ')}
                >
                    <Link
                        hidden={props.isEmbedded}
                        className='btn btn-xs bg-light-hover'
                        to={TransportService.getTransportTypeUrl(transportType)}
                        onClick={() => transportService.unsetTransportContext()}
                    >
                        <ArrowsOutSimple className=' ' />
                    </Link>
                    <EtcMenu hidden={props.showDelete === false}>
                        <Delete
                            disabled={!transportType?.uuid}
                            className='btn btn-sm btn-outline-danger border-0'
                            deleteFn={async () => {
                                if (!transportType) return
                                return transportService.deleteTransportType(transportType).then(() => {
                                    Utils.deletedToast(`${ProductService.elementTitle()} has been deleted`)
                                    if (props.isEmbedded) navigate(InventoryService.getCurrentView())
                                })
                            }}
                        />
                    </EtcMenu>
                </div>
            </div>
            <div className='d-flex justify-content-between gap-3' style={props.segmentListStyle}>
                <div className='align-items-center flex-grow-1'>
                    <div>
                        {transportType &&
                            transportType?.segments?.map((s, idx) => (
                                <TransportSegmentEditor
                                    key={`seg-${idx}`}
                                    idx={idx}
                                    transportType={transportType!}
                                    segment={s}
                                    // onDistance={(dr) => {
                                    //     if (!s.uuid) return
                                    //     setDistances((d) => {
                                    //         d.set(s.uuid!, dr)
                                    //         return new Map(d)
                                    //     })
                                    // }}
                                    onSaving={(isSaving) => setSaving(isSaving)}
                                    onSaved={(tt) => props.onSave?.(tt)}
                                />
                            ))}
                    </div>
                    <div className='border-top mt-2 pt-2 pb-2'>
                        <Button
                            className='btn btn-sm btn-light border bg-primary-hover shadow-none'
                            onClick={() => {
                                const segments = [...(transportType?.segments || [])]
                                segments.push({
                                    origin: segments[segments.length - 1]?.destination,
                                    order: segments.length,
                                })
                                save({ segments })
                            }}
                        >
                            <Plus /> Add leg
                        </Button>
                    </div>
                </div>
                <div hidden={props.isEmbedded} className='ms-4 small w-33'>
                    <div className='d-flex flex-column gap-2 pt-2'>
                        {/*{mapProps && <GoogleMap {...mapProps} size={130} extraClassName='flex-shrink-0' />}*/}
                        <Link
                            to={`${ActivityService.webRootActivities}?transportType=${transportType?.uuid}`}
                            onClick={(e) => {
                                if (Utils.isModifierKey(e)) return
                                transportService.unsetTransportContext()
                            }}
                            hidden={!transportType?.activityCo2e}
                            className='underline-on-hover'
                        >
                            <CO2e
                                onZero='dash'
                                co2e={transportType?.activityCo2e}
                                unitSize='large'
                                className='h6'
                                unitsClassName='small text-muted'
                            />{' '}
                            in {transportType?.activityCount}{' '}
                            {ActivityService.webTitle(transportType?.activityCount !== 1)}
                        </Link>
                        <div hidden={!transportType?.partOfCount}>
                            <div>Used in:</div>
                            <TransportTypeLinks transportType={transportType} />
                        </div>
                    </div>
                </div>
            </div>
            {!props.isEmbedded && (
                <div className='d-flex align-items-center justify-content-between border-top mt-3 pt-2'>
                    <div className='flex-grow-1 text-height-1'>
                        <div className='d-flex align-items-center justify-content-between gap-2 small'>
                            <span>Total distance</span>
                            <NiceAmount
                                amount={{ quantity: transportType?.totalKm, unit: UnitService.unitByCode['km'] }}
                                prettyNumberProps={{ precision: 1 }}
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-between gap-2 small mt-1'>
                            <span>Emissions</span>
                            <CO2e co2e={transportType?.co2e} functionalUnitClassName='ms-1' functionalUnit='tkm' />
                        </div>
                        <ImpactTable node={transportType} openInModal={true} />
                        <Footnotes node={transportType} saving={saving} />
                    </div>
                    <div className='ms-5 w-33 align-self-stretch d-flex align-items-center justify-content-end'>
                        <Button hidden={!props.onDone} onClick={props.onDone} className='btn btn-sm btn-dark'>
                            {!transportType?.uuid ? 'Create' : 'Done'}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TransportDetail
