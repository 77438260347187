import { StandardAttributes } from '../../types'
import { MutableRefObject, ReactNode, useContext, useMemo } from 'react'
import DataImportService, { DataImportResponse, DataImportType, DromoSelectValue } from '../../services/dataImport'
import { VariableServicesContext } from '../../services'
import DromoReactUploader from 'dromo-uploader-react'
import { DownloadSimple } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import { PrettyNumber } from '../PrettyNumber'
import { Inventory, InventoryService, InventoryType } from '../../services/inventory'

export const BomImport = (
    props: StandardAttributes & {
        children?: ReactNode
        showButton?: boolean
        dromoRef?: MutableRefObject<any>
        onDone?: (result: DataImportResponse) => void
        onCancel?: () => void
        onError?: (e: any) => void
    },
) => {
    const { inputService, dataImportService, analyticsService } = useContext(VariableServicesContext)

    const button = useMemo(() => {
        if (props.children) return props.children
        if (props.showButton === false) return null
        return (
            <>
                <DownloadSimple /> Import
            </>
        )
    }, [props.children, props.showButton])

    const productsAndMaterials = useMemo(
        () => InventoryService.list.filter((i) => i.type === InventoryType.Product),
        [],
    )

    const elements: DromoSelectValue[] = useMemo(
        () =>
            dataImportService.toDromoSelectValues<Inventory>(productsAndMaterials, (item) => {
                let label = item.name
                if (item.originalProduct?.sku) label += ` (${item.originalProduct.sku})`
                if (item.productOf?.name) label += ` by ${item.productOf.name}`
                if (Utils.inDebugMode()) label += ` (${item.uuid})`
                return label
            }),
        [productsAndMaterials],
    )

    return (
        <DromoReactUploader
            schemaName='Import'
            schemaId='bom-import'
            ref={props.dromoRef}
            className={props.className || 'btn btn-primary'}
            licenseKey={DataImportService.dromoLicenseKey}
            stepHooks={[
                {
                    type: 'UPLOAD_STEP',
                    callback: (_, data) => {
                        analyticsService.track('Bom Import: Uploaded CSV', { rows: (data as any)?.dataPreview?.length })
                    },
                },
                dataImportService.bomReviewStepHook(DataImportType.Inputs),
            ]}
            fields={[
                {
                    label: 'Name',
                    key: 'name',
                    alternateMatches: ['item', 'name', 'title', 'part', 'part name', 'navn', 'del', 'del navn'],
                    validators: [{ validate: 'required' }],
                },
                dataImportService.itemNameSelect({ key: 'sourceString', label: 'Material', selectOptions: elements }),
                dataImportService.quantityField(),
                dataImportService.unitField('Unit', 'unitCode'),
            ]}
            columnHooks={[
                dataImportService.numberColumnHook(dataImportService.quantityField().key),
                dataImportService.unitColumnHook('unitCode'),
                dataImportService.itemNameColumnHook({ key: 'sourceString', selectOptions: elements }),
            ]}
            settings={dataImportService.dromoSettings({
                importIdentifier: 'Bom',
                templateDownloadFilename: 'Bom_Import.csv',
            })}
            user={dataImportService.dromoUser()}
            onResults={(response) => {
                // console.log({ response })
                analyticsService.track('BOM Import: Submitted Data', { rows: response.length })
                const inProgressToast = Utils.infoToast(
                    <>
                        <span className='spinner-border spinner-border-sm' /> Importing BOM
                    </>,
                    { position: 'bottom-right', autoClose: 1500 },
                )
                inputService
                    .importBom(response)
                    .then((result) => {
                        // console.log({ result })
                        analyticsService.track('Bom Import: Success', { nodesCreated: result.imported })
                        Utils.dismissToast(inProgressToast)
                        Utils.successToast(
                            <span>
                                Imported <PrettyNumber num={result.imported} suffix=' item' pluralizeSuffix={true} />
                            </span>,
                            { autoClose: 3000 },
                        )
                        props.onDone?.(result)
                    })
                    .catch((errorMessage) => {
                        analyticsService.track('Bom Import: Error', { errorMessage })
                        props.onError?.(errorMessage)
                    })
            }}
            onCancel={props.onCancel}
        >
            {button}
        </DromoReactUploader>
    )
}
